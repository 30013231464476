export default function ProductImage({ item }: { item: { hcpc_code: string, item_description: string } }) {
  return (
    <div>
      <img
        src={`https://aeroflowbreastpumps.com/media/wysiwyg/cgm/${item.hcpc_code}.jpg`}
        alt={item.item_description}
        height="50"
        width="50"
      />
    </div>
  )
}