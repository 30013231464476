import { forwardRef, useEffect, useRef, useState } from "react"
import DropdownTextInput, { type DropdownTextInputProps } from "./DropdownTextInput"
import { TextInputProps } from "../types"
import { usaStates } from "../constants"

export type StateDropdownInputProps = {
  value: string;
  onChange: ( _e: React.ChangeEvent<HTMLInputElement> ) => void;
} & Partial<TextInputProps>

/* Important Note about this component:
  * The eccentric nature of the state dropdown requires us to create a synthetic event to send to the parent onChange.
  * This is due to the fact that the value of the text input is a state name (North Carolina), but the onChange event is a state code (NC)
  * We need to create a synthetic event with the state code and sends it to the parent onChange.
  * We also need a subscription to the value prop (stateCode format) to update the local state (stateName format) when the parent changes its value prop.
  * There is also an ignore sync ref to ignore the subscription sync when the value is updated from the child so we don't autocomplete the state name from the user typing state code.
  * For example, the parent wants to pass in the value NC, we alter that to North Carolina.
  * After, the user makes a change, we want to unsubscribe from the parent value and use the local state
  * We need to remove this subscription because if a user types AL, it will change to Alabama, when they may want to type Alaska.
  * I hope this makes sense. It's a bit of a complex component.
  * Also, I took this approach rather than a initialValue prop because I wanted to keep the component as a parent-controlled component, since many our api requests come after mount.
  */

const StateDropdownInput = forwardRef<Partial<DropdownTextInputProps>, StateDropdownInputProps>( ( props, ref ) => {
  const [ stateName, setStateName ] = useState<string>( getStateName( props.value ) )
  const shouldIgnoreSubscriptionSyncRef = useRef( false )

  useEffect( () => {
    if ( !shouldIgnoreSubscriptionSyncRef.current ) { // We want to ignore the changes driven by local state, but not ignore parent changes. This is why we use a ref to keep track of the local state changes
      // Create a subscription to the value prop to update the stateName
      setStateName( getStateName( props.value ) )
    }
  }, [ props.value ] )

  const createSyntheticEvent = ( stateName: string ) => {
    const state = getStateCode( stateName ) // Converts the state name to the state code
    const syntheticTarget = {
      name: props?.name || `state`,
      value: state // This is the state code
    }

    return {
      currentTarget: {
        ...syntheticTarget
      },
      target: {
        ...syntheticTarget
      }
    } as React.ChangeEvent<HTMLInputElement>
  }

  const sendStateChangeToParent = ( stateName: string ) => {
    // If the value is a valid stateName, then create a synthetic event with the state code
    const isCompleteEntry = stateName.length >= 2 && getStateCode( stateName )
    const syntheticEvent = isCompleteEntry ? createSyntheticEvent( stateName ) : createSyntheticEvent( `` )
    shouldIgnoreSubscriptionSyncRef.current = true
    props.onChange( syntheticEvent )
  }


  const handleLocalChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    const stateName = e.currentTarget.value
    setStateName( stateName )

    sendStateChangeToParent( stateName )
  }

  const handleItemClick = ( stateName: string ) => {
    setStateName( stateName )
    sendStateChangeToParent( stateName )
  }

  return (
    <DropdownTextInput
      {...props}
      name={props?.name || `state`}
      placeholder={props?.placeholder || `Type or Select a State`}
      label={props?.label || `State`}
      labelClassName={props?.labelClassName || `input-label text-left`}
      inputClassName={props?.inputClassName || `input bg-white`}
      ref={ref}
      value={stateName}
      onChange={handleLocalChange}
      items={usaStates.map( state => state.text )}
      onItemClick={handleItemClick}
      customItemFilter={( item : string ) => {
        if ( item.toLowerCase() === stateName.toLowerCase() ) return false // If the item is the same as the stateName, then don't show it (This hides the dropdown when the user has typed the full state name)

        return item.toLowerCase().startsWith( stateName.toLowerCase() ) || getStateCode( stateName ) === getStateCode( item )
      }}
      dropdownBehavior="onFocus"
    />
  )
})

export default StateDropdownInput

export function getStateCode( stateName: string ) {
  return usaStates.find( state => state.text.toLowerCase() === stateName.toLowerCase() || state.value.toLowerCase() === stateName.toLowerCase() )?.value || ``
}

export function getStateName( state: string ) {
  return usaStates.find( s => s.value.toLowerCase() === state.toLowerCase() || s.text.toLowerCase() === state.toLowerCase() )?.text || ``
}