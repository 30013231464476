import { useState } from 'react'
import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'
import { notInterestedLinks } from 'modules/aobPayments/constants'
import { fetchSubmitNotInterestedReason } from 'modules/aobPayments/fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'



function NotInterestedTooltip({setNotInterested, redirectPath}) {

  const [ submitLoading, setSubmitLoading ] = useState( false )

  const navigate = useNavigate()

  const handleSubmitNotInterested = ( reason ) => {
    setSubmitLoading( true )
    fetchSubmitNotInterestedReason( reason )
      .then ( ( data ) => {
        if ( data?.status !== 200 || data?.error ) {
          throw new Error( `CGM AOB Payments Portal Error: Error submitting not interested reason`, {
            cause: data
          })
        }
      })
      .catch( error => {
        reportToSentry( new Error( `CGM AOB Payments Portal Error: Error submitting not interested reason`, {
          cause: error
        }) )
      })
      .finally( () => {
        setNotInterested( true )
        navigate( redirectPath )
      })
  }

  const NotInterestedTooltipLink = styled.p`
    text-decoration: underline;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 8px;
    color: ${tailwindColors.teal};
    text-decoration-color: ${tailwindColors.teal};
    text-decoration-thickness: 1px;
    cursor: pointer;
  `

  return (
    <div className="m-auto">
      <h4 className="w-11/12 p-2 text-base tracking-wide">{`I am no longer interested in receiving supplies through insurance`}</h4>
      <div className="px-2">
        {
          submitLoading ?
            <div className="py-10">
              <LoadingSpinner />
            </div>
            :
            notInterestedLinks.map( ( link, i ) => {
              return (
                <NotInterestedTooltipLink
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={() => { return handleSubmitNotInterested( link ) }}
                >
                  {link}
                </NotInterestedTooltipLink>
              )
            })
        }
      </div>
    </div>
  )
}

NotInterestedTooltip.propTypes = {
  setNotInterested: PropTypes.func,
  redirectPath: PropTypes.string
}

export default NotInterestedTooltip