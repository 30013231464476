import { type Address, SmartyAddressValidation } from 'types/address'

/* Takes in a field from the api address type and returns the field name in the format to be consumed by the ui */
export function serializeAddressFieldFromApi( apiAddressField : string, shouldBePretty? : boolean ) : string {
  if ( apiAddressField === `address1` ) return shouldBePretty ? `street address` : `street`
  if ( apiAddressField === `address2` ) return shouldBePretty ? `street address` : `street2`
  if ( apiAddressField === `zip` ) return shouldBePretty ? `zip code` : `zipCode`

  return apiAddressField
}

/** Takes in Address type and returns the original_address fields in the format to be consumed by the api  */
export function serializeToOriginalAddress( address : Address ) : SmartyAddressValidation {
  return {
    street: address.street,
    ...( address.street2 ? {
      secondary: address.street2
    } : {}),
    city: address.city,
    state: address.state,
    zipcode: address.zipCode
  }
}

/**  Takes in the verifiedAddress format received from the api and returns the Address type */
export function serializeFromVerifiedAddress( street: string, verifiedAddress: Record<string, any> ) : Address {

  return {
    street,
    street2: ``,
    city: verifiedAddress.city_name,
    state: verifiedAddress.state_abbreviation,
    zipCode: verifiedAddress.zipcode
  }
}

export function serializeToPatientsUpdateAddress( address : Address ) {
  return {
    street1: address.street,
    ...( address.street2?.length ? {
      street2: address.street2
    } : {}),
    city: address.city,
    state: address.state,
    zip: address.zipCode
  }
}