/* Hello there!

This file is used to manage the styles for the AddressBlock component.
This file allows for one to override the default styles for the AddressBlock component
through a zustand store. This is done by calling the overrideAddressBlockStyle function
and passing in the styles you want to override. The styles you pass in will be merged with
the default styles. The default styles are exported as defaultStyles. You can also override
the default styles by calling the setStyleOverrides function from the useAddressStyleStore hook.

example usage:
import { overrideAddressBlockStyle } from 'components/addressBlock2.0/style'

function Component() {
  overrideAddressBlockStyle({
    headerStyle: `text-bold`
    view: {
      textStyle: `text-bold`
    }
  })

  return (
    <AddressBlock />
  )
  }
*/


import { useEffect } from 'react'
import { create } from 'zustand'

export type AddressBlockStyles = {
  headerStyle?: string; // This is the style for the headerText
  containerStyle?: string; // This is the style for the div that wraps the address block
  wrapperStyle?: string; // This is the style for the wrapper div that wraps the entire component (AddressBlock + Header)
  buttonClassName?: string; // This is the style for the button
  ctaStyle?: string; // This is the style for the call to action text
  textInputClassName?: string; // This is the style for the text input
  view?: ViewStyleOverrides | Record<string, string>;
  // Down the line we may want to add edit, confirm and other view styles
}

export interface ViewStyleOverrides {
  inputWrapperClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  textStyle?: string;
  editActionClassName?: string;
}

export type AddressStyleStore = {
  styleOverrides: AddressBlockStyles;
  setStyleOverrides: ( _styleOverrides : AddressBlockStyles ) => void;
}

export const defaultStyles = {
  headerStyle: `text-center text-3xl capitalize font-sans tracking-wide mb-10`,
  containerStyle: ``,
  wrapperStyle: `mx-auto w-full max-w-xl text-center p-2`,
  textInputClassName: `input`,
  buttonClassName: `btn btn-primary mx-auto`,
  ctaStyle: `text-teal3 cursor-pointer hover:underline hover:decoration-teal3 active:text-black active:decoration-black`,
  view: {
    inputWrapperClassName: `flex justify-center mt-10 max-w-sm cursor-pointer gap-2 mx-auto`,
    labelClassName: `text-lg font-light cursor-pointer w-2/3`,
    inputClassName: `themed-checkboxes mt-1 w-[20px]`,
    textStyle: `text-lg font-light capitalize`
  }
}

export const useAddressStyleStore = create<AddressStyleStore>()(
  ( set ) => {
    return {
      styleOverrides: {
        ...defaultStyles
      }, // These are the default styles,
      setStyleOverrides: ( _styleOverrides ) => {
        const newStyles = buildStyles( _styleOverrides )()
        set({
          styleOverrides: {
            ...newStyles
          }
        })
      }
    }
  }
)

export const overrideAddressBlockStyle = ( _styleOverrides : AddressBlockStyles ) => {
  const { setStyleOverrides } = useAddressStyleStore()
  useEffect( () => {
    setStyleOverrides({
      ..._styleOverrides
    })
  }, [] )

  return null
}


/* Begin Utility Functions */
// These utilities allow us to merge the default styles with the overrides

export const buildStyles = ( styleOverrides: AddressBlockStyles ) => () => {
  const { view: viewOverrides } = styleOverrides

  return {
    view: mergeStyles( defaultStyles.view, viewOverrides as Record<string, string> ),
    wrapperStyle: styleOverrides.wrapperStyle ?? defaultStyles.wrapperStyle,
    containerStyle: styleOverrides.containerStyle ?? defaultStyles.containerStyle,
    headerStyle: styleOverrides.headerStyle ?? defaultStyles.headerStyle,
    ctaStyle: styleOverrides.ctaStyle ?? defaultStyles.ctaStyle,
    textInputClassName: styleOverrides.textInputClassName ?? defaultStyles.textInputClassName,
    buttonClassName: styleOverrides.buttonClassName ?? defaultStyles.buttonClassName
  }
}

// Utility function to merge defaults with overrides
export const mergeStyles = ( defaults: Record<string, string>, overrides: Record<string, string> ) => {
  if ( !overrides ) return defaults

  return Object.keys( defaults ).reduce( ( acc : Record<string, string>, key : string ) => {
    acc[key] = overrides[key] || defaults[key]

    return acc
  }, {})
}