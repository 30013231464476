import Nav from 'components/checkout/Nav'

export default function NotInterested({ ...pageProps }) {
  window.scrollTo({
    top: 0,
    behavior: `smooth`
  })

  return (
    <div className="order-confirmation">
      <Nav prevPage={pageProps.prevPage} onlyLogo />
      <div className="p-8 md:p-0">
        <h1 className="font-sourceserif4 text-center text-2xl md:text-3xl mb-12">
          {`Thank you`}
        </h1>
        <div className="text-center my-12 px-5 md:px-8 py-16 bg-skyBlue">
          <p className="font-sourceserif4 text-xs md:text-xl">{`We will not ship out the reader/sensors at this time. If you have any questions, please contact Customer Support at `}<a href="tel:18664566755">{`1-866-456-6755`}</a></p>
        </div>
      </div>
    </div>
  )
}
