import { useEffect, useState } from 'react'
import { fetchRxStatus } from '../fetchPromises'

export default function useFetchRxStatus( patientId: string ) {
  const [ rxStatus, setRxStatus ] = useState( )
  const [ error, setError ] = useState( null )
  const [ loading, setLoadiong ] = useState( true )


  useEffect( () => {
    const abortController = new AbortController()
    const {signal} = abortController
    fetchRxStatus( patientId, signal ).then( ( data ) => {
      setRxStatus( data )
      setLoadiong( false )
    })
      .catch( ( error ) => {
        setError( error )
        setLoadiong( false )
      })

    return () => abortController.abort()
  }, [] )

  return {
    rxStatus,
    error,
    loading
  }
}