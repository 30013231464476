import { Address } from "types/address"
import Highlight from "./Highlight"

/* Note: highlightedComponents is no longer used, we use the HighlightP component now - but keeping for backwards compatibility */
export default function AddressP({address, className } : { address: Address, highlightedComponents?: string[], className?: string}) : JSX.Element {
  const { city, state, zipCode } = address

  const streetDisplay = `${address.street} ${address.street2 ?? ``}`.trim()

  return (
    <p className={className ?? `text-center self-start`}>
      <Highlight shouldNotHighlight>{streetDisplay}</Highlight>{`, `}
      <Highlight shouldNotHighlight>{city}</Highlight>{`, `}
      <Highlight shouldNotHighlight>{state}</Highlight>{` `}
      <Highlight shouldNotHighlight>{zipCode}</Highlight>
    </p>
  )
}