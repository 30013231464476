import * as React from 'react'
import { getPatientAddress } from 'utils/patientInfoApi'
import { useAddressStore } from 'state'
import { getAuthHeader } from 'utils/getHash'
import { reportToSentry } from 'utils/reportToSentry'
import { capitalizeWords } from 'utils/utils'
import { Address } from 'types/address'
import AobInvoice from 'types/aobInvoice'

/* This component syncs our AddressBlock with the customer's address in the database.
This is useful for when we have the customer's address saved in the database, but we want to use the autocomplete api to verify the address.
This component will fetch the customer's address on mount and set the address in state.
This will also set the current view to `complete` if the customer's address is found in the database.
We also sync the customer's first and last name from the qualify form to the address block.
*/

export const convertAddressToStandardCase = ( address: Address ) : Address => {
  const convertAddressToStandardCase = {
    ...address
  } as Address

  Object.keys( convertAddressToStandardCase ).forEach( key => {
    ( convertAddressToStandardCase as Record<string, any> )[key] = capitalizeWords( ( convertAddressToStandardCase as Record<string, any> )[key] )
  })

  return convertAddressToStandardCase
}

export default function useExternalSync({ invoice, shouldSkipMountFetch = false } : { shouldSkipMountFetch: boolean, invoice?: AobInvoice }) {

  const [ initialLoading, setInitialLoading ] = React.useState<boolean>( true )
  const {setCurrentView, address, setAddress} = useAddressStore()

  React.useEffect( () => {

    let mounted = true

    if ( shouldSkipMountFetch ) {
      setAddress({
        ...convertAddressToStandardCase( address ),
        state: address.state
      })

      return setInitialLoading( false )
    }
    getPatientAddress( getAuthHeader() ).then( _address => {

      if ( !mounted ) return
      const hasAddressSaved = _address?.street?.length
      if ( hasAddressSaved ) {
        setAddress({
          firstName: invoice?.first_name ? capitalizeWords( invoice?.first_name ) : ``,
          lastName: invoice?.last_name ? capitalizeWords( invoice?.last_name ) : ``,
          ...convertAddressToStandardCase( _address ),
          zipCode: _address.zip,
          state: _address.state
        })


      }
      setCurrentView( hasAddressSaved ? `complete` : `edit`, false )
    })
      .catch( () => reportToSentry( `AddressBlock2.0 - Failed to fetch address on mount ` ) )
      .finally( () => mounted && setInitialLoading( false ) )

    return () => { mounted = false }
  }, [] )

  return {
    initialLoading
  }
}