/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckoutReview } from "components/checkoutReview"
import { Footer } from "components/footer"
import { NotInterested } from "components/notInterested"
import { OrderConfirmation } from "components/orderConfirmation"
import PageNavigator from "components/pageNavigator"
import useRetrieveAOBData from "modules/aobPayments/customHooks/useRetrieveAOBData"
import PropTypes from 'prop-types'
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useAddressStore } from "state"
import { AobError, AobLoader } from '../aobPayments/components'
import CheckoutLanding from "./CheckoutLanding"
import { DOCTOR_REFER_PAGES } from "./constants"
import { useFetchProductPricingData } from "./customHooks"
import { useDoctorReferStore } from "./DoctorReferStore"
import ReferLanding from "./ReferLanding"

export default function DoctorReferPortal() {
  const [ invoice, setInvoice ] = useState( null )
  const [ error, setError ] = useState( null )
  useRetrieveAOBData( setInvoice, setError )
  const { setCurrentView, setVerifiedAddress } = useAddressStore()

  useEffect( () => {
    setCurrentView( `complete` )
    setVerifiedAddress({
      street: ``,
      street2: ``,
      city: ``,
      state: ``,
      zipCode: ``
    })
  }, [] )

  const { productPricingData, loading: loadingProductPricing } = useFetchProductPricingData()
  const [ orderComplete, pageLoadCount ] = useDoctorReferStore( ( state ) => [ state.refer.orderComplete, state.refer.pageLoadCount ] )
  const { pathname } = useLocation()

  if ( error ) return <AobError message={error} />

  if ( !invoice ) return <AobLoader message={`Please hold tight while we grab your invoice details...`} withLogo />

  // check if the order is already complete and display a message to contact customer support
  if ( orderComplete && pathname !== `/payments/doctor-refer/confirmation` ) {
    return (
      <>
        <div className="h-[calc(100vh_-_116px)] flex flex-col justify-center items-center p-10">
          <p className="text-center">
            {`You have already completed your order. If you feel you have received this message in error, please contact Customer Support at `}
            <a className="underline underline-offset-4" href="tel:18664566755">{`1-866-456-6755`}</a>
          </p>
        </div>
        <Footer />
      </>
    )
  }

  return (
    <div className="doctor-referral flex flex-col justify-between relative">
      <PageNavigator
        pageNames={DOCTOR_REFER_PAGES} childSharedProps={{
          invoice,
          productPricingData
        }}
      >
        <ReferLanding />
        <CheckoutLanding />
        <CheckoutReview />
        <OrderConfirmation />
        <NotInterested />
      </PageNavigator>
      <Footer />
    </div>
  )
}

DoctorReferPortal.propTypes = {
  setNotInterested: PropTypes.func,
  notInterested: PropTypes.bool
}