
export const getProductPricing = async ( authorization : string ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/product-pricing`, {
    method: `GET`,
    headers: {
      "Authorization": authorization
    }
  })

  if ( response?.ok ) {
    return await response?.json()
  }
  throw new Error( response?.statusText ?? `Wrongly formatted response returned` )
}

export const getPatientAddress = async ( authorization : string ) => {
  const response = await getProductPricing( authorization ) // We want any error to bubble up which is why there is no .catch
  const { address } = response.data.patient

  return address
}