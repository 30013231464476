import AddressP from "../components/AddressP"
import { useState } from "react"
import { useAddressStore } from "state"
import { useAddressStyleStore } from "../style"
import { useUpdateAddress } from "../hooks/useUpdateAddress"
import { Modal } from 'components/modal'

export default function ConfirmAddress({ onSuccessfulConfirm, hideAddressConfirm } : { onSuccessfulConfirm?: () => void; hideAddressConfirm?: boolean }) : JSX.Element {
  const { address, setCurrentView, setAddressWebConfirmed, addressPatientConfirmed } = useAddressStore()

  const { styleOverrides } = useAddressStyleStore()
  const { updatePatientAddress } = useUpdateAddress()

  const { ctaStyle, buttonClassName } = styleOverrides
  const [ , setOpenModal ] = useState( true )

  const handleConfirm = () => {
    setAddressWebConfirmed( false ) // This lets internal users know that the address was unable to be verified by the verification api
    setCurrentView( `complete` )
    if ( hideAddressConfirm ) updatePatientAddress( addressPatientConfirmed, false )
    if ( onSuccessfulConfirm ) onSuccessfulConfirm()
  }

  return (
    <Modal
      isOpen
      defaultCloseButton={false}
      excludeButtonText="Add My Secondary Insurance"
      onClose={() => setOpenModal( false )}
    >
      <div>
        <p className="text-base text-center mt-5 w-full bg-[#E90C0C] text-white py-2 rounded-t-md">{`Address Cannot Be Validated`}</p>
        <div className="p-3 rounded-xl flex flex-col items-center gap-6 max-w-md mx-auto mt-5">
          <p className="font-light text-center">{`We were not able to verify your address, which could lead to delivery delays or lost packages. Your address entered may be missing apartment, building or lot number.`}</p>
          <p className="font-light text-center">{`Do you want to edit this address or continue with your originally entered address?`}</p>
          <AddressP address={address} className="text-center text-deepSleepBlue" />
          <button
            className={buttonClassName} onClick={() => {
              setCurrentView( `edit` )
              setOpenModal( false )
            }}
          >
            {`Edit`}
          </button>
          <p className={ctaStyle} onClick={handleConfirm}>{`Continue`}</p>
        </div>
      </div>
    </Modal>
  )
}