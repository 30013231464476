export default function Faq() {
  return (
    <div className="bg-[#E9DFEB]" id="faq">
      <div className="p-8 text-[#222222] font-sourceserif4 max-w-[1110px] mx-auto">
        <p className="text-2xl pb-4">
          {`Frequently Asked Questions`}
        </p>
        <details className="pt-4 pb-4">
          <summary className="text-lg list-none cursor-pointer pr-4 cursor-pointer">
            {`Why am I receiving this link?`}
          </summary>
          <p className="text-gray-800 font-inter text-xs font-light mt-2">
            {`As a trusted partner of your healthcare provider, they’ve referred you to us to check your coverage for insurance-covered continuous glucose monitor (CGM) supplies.`}
          </p>
        </details>
        <details className="pt-4 pb-4">
          <summary className="text-lg list-none cursor-pointer pr-4 cursor-pointer">
            {`What is Aeroflow Diabetes?`}
          </summary>
          <p className="text-gray-800 font-inter text-xs font-light mt-2">
            {`Aeroflow Diabetes helps patients get the CGM supplies they need through their insurance benefits. We offer a wide range of high-quality products to meet different needs and deliver them directly to your door. Our Diabetes Specialists work with you to ensure you have the best CGM system you can get through your insurance plan.`}
          </p>
        </details>
        <details className="pt-4 pb-4">
          <summary className="text-lg list-none cursor-pointer pr-4 cursor-pointer">
            {`Why should I order my CGM supplies through Aeroflow Diabetes?`}
          </summary>
          <p className="text-gray-800 font-inter text-xs font-light mt-2">
            {`As a trusted partner of your healthcare provider, we make it easy to determine your eligibility for insurance-covered CGM supplies through our online form. Our Diabetes Specialists are available to assist you throughout the process, and we offer free shipping each time you refill your supplies.`}
          </p>
        </details>
        <details className="pt-4 pb-4">
          <summary className="text-lg list-none cursor-pointer pr-4 cursor-pointer">
            {`How much does it cost to order my CGM supplies through Aeroflow Diabetes?`}
          </summary>
          <p className="text-gray-800 font-inter text-xs font-light mt-2">
            {`The cost of a CGM varies based on insurance coverage - but don't worry - we will display a personalized breakdown of what your insurance will cover and what your out-of-pocket costs will be before you place your order.`}
          </p>
        </details>
      </div>
    </div>
  )
}