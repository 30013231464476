import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { RadioCheckboxInputCustom } from 'components/inputs/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { useFetch } from 'hooks'
import { useEffect, useState } from 'react'
import { usePayMethodsStore } from 'state'
import { getAuthHeader } from 'utils/getHash'
import AddNewPayment from '../AddNewPayment'
import { EditBankMethodTab, EditCardMethodTab } from '../Edit'
import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod, PaymentProfileResponse } from '../types'
import { putOrPostUrlPaymentMethod, serializeBankPaymentMethod, serializeCardPaymentMethod, serializePaymentProfileResponse } from '../utils'

function PaymentController() {
  const [ payMethods, setPayMethods ] = usePayMethodsStore( ( state ) => [ state.payMethods, state.setPayMethods ] )
  const [ shouldSkipFetch, setShouldSkipFetch ] = usePayMethodsStore( ( state ) => [ state.shouldSkipFetch, state.setShouldSkipFetch ] )
  const [ selectedPayMethod, setSelectedPayMethod ] = usePayMethodsStore( ( state ) => [ state.selectedPayMethod, state.setSelectedPayMethod ] )
  const [ editingPayMethod, setEditingPayMethod ] = useState<IPaymentMethod | undefined>( undefined )
  const {setPayMethod: setPayMethodStore, removePayMethod } = usePayMethodsStore()
  const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState<IPaymentMethod | undefined>( undefined )
  const [ fetchError, setFetchError ] = useState<string>( `` )
  const [ loading, setLoading ] = useState<boolean>( false )
  const {lazyFetch : savePayMethodToAuthnet, isLoading : isSaveLoading, error: saveError } = useFetch<PaymentProfileResponse>( putOrPostUrlPaymentMethod( editingPayMethod ?? null ), {
    method: `PUT`
  })

  const [ showAddNewPayment, setShowAddNewPayment ] = useState( false )


  useEffect( () => {
    if ( payMethods.length && !selectedPaymentMethod ) {
      setSelectedPaymentMethod( payMethods[0] )
    }

    setEditingPayMethod( payMethods?.find( ( payMethod ) => payMethod.isEditing === true ) )

  }, [ payMethods ] )

  const { isLoading } = useFetch<PaymentProfileResponse>( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/profile`, {
    method: `GET`
  }, {
    skipOnMount: shouldSkipFetch,
    onErrorOverride: ( _error : unknown ) => {
      // We don't want to do anything on error since this is fetching existing payment profiles
    },
    onSuccess: ( data: PaymentProfileResponse ) => {
      setShouldSkipFetch( true ) // This allows for us to not fetch the payment methods again if they have already been fetched from another block
      if ( data?.messages?.resultCode?.toLowerCase() === `ok` ) {
        const _payMethods = serializePaymentProfileResponse( data )
        if ( _payMethods?.length ) setSelectedPayMethod( _payMethods[0] )
        setPayMethods( _payMethods )
        setShouldSkipFetch( true )
      }
    }
  })

  const handleCancel = () => {
    const existingPayMethod = payMethods.find( ( payMethod ) => payMethod.paymentMethodId === editingPayMethod?.paymentMethodId )

    if ( existingPayMethod ) {
      setPayMethodStore({
        ...existingPayMethod,
        isEditing: false
      } as IPaymentMethod )
    }
  }

  const handleEdit = ( payMethod:IPaymentMethod ) => {
    setPayMethodStore({
      ...payMethod,
      isEditing: true
    })
  }

  async function deletePaymentMethod( payMethod:IPaymentMethod ) {
    try {
      setLoading( true )
      setFetchError( `` )
      const fetchResponse = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile/${payMethod?.paymentProfileId}`, {
        method: `DELETE`,
        headers: {
          Authorization: getAuthHeader()
        }
      })
      const response = await fetchResponse.json()
      if ( response?.messages?.resultCode?.toLowerCase() === `ok` ) {
        // if this was the only card we had, show the add new payment method
        if ( payMethods?.length === 1 ) setShowAddNewPayment( true )
        else {
          const deletedMethodIndex = payMethods.findIndex( ( method ) => method.paymentMethodId === payMethod.paymentMethodId )
          if ( selectedPayMethod?.paymentMethodId === payMethod.paymentMethodId ) setSelectedPayMethod( payMethods[ deletedMethodIndex === 0 ? 1 : 0] )
        }

        return removePayMethod( payMethod.paymentMethodId )
      } else return setFetchError( response?.messages?.message?.[0]?.text ?? `We had some trouble deleting your payment. Please try again.` )
    } catch {
      return setFetchError( `We had some trouble deleting your payment. Please try again.` )
    } finally {
      setLoading( false )
    }
  }

  async function savePaymentMethod() {
    setFetchError( `` )
    const serializedPayMethod = editingPayMethod?.paymentMethodType === `card` ? serializeCardPaymentMethod( editingPayMethod as CardPaymentMethod ) : serializeBankPaymentMethod( editingPayMethod as BankPaymentMethod )
    const data = await savePayMethodToAuthnet( serializedPayMethod ) // We might need to serialize this
    if ( data?.messages?.resultCode?.toLowerCase() === `ok` ) {
      setPayMethodStore({
        ...editingPayMethod,
        paymentProfileId: data.customerPaymentProfileId, // append the customerProfileId to the payMethod
        isEditing: false
      } as IPaymentMethod )
    } else return setFetchError( data?.messages?.message?.[0]?.text ?? `We are unable to process your payment method at this time` )
  }

  if ( isLoading || loading ) return (
    <div className="max-w-lg mx-auto my-10">
      <p className="my-2 font-light text-center">{`Loading payment profiles...`}</p>
      <LoadingSpinner />
    </div> )

  if ( showAddNewPayment || !payMethods?.length ) return (
    <AddNewPayment
      handleCancel={() => setShowAddNewPayment( false )}
      onSuccess={() => setShowAddNewPayment( false )}
    />
  )

  if ( editingPayMethod ) {
    if ( editingPayMethod.paymentMethodType === `card` ) return (
      <EditCardMethodTab
        payMethod={editingPayMethod as CardPaymentMethod}
        setPayMethod={setEditingPayMethod}
        onSave={savePaymentMethod}
        newPayment={false}
        onCancel={handleCancel}
        displayLoading={isSaveLoading}
        displayError={fetchError}
      />
    )

    return (
      <EditBankMethodTab
        payMethod={editingPayMethod as BankPaymentMethod}
        setPayMethod={setEditingPayMethod}
        onSave={savePaymentMethod}
        onCancel={handleCancel}
        displayLoading={isSaveLoading}
        displayError={fetchError || ( saveError?.toString() ?? `` )}
      />
    )
  }



  return (
    <>
      <p className="text-xl md:text-2xl font-sourceserif4 mb-4">{`Pay by:`}</p>
      {payMethods.map( ( payMethod ) => {
        const { paymentMethodId } = payMethod

        return (
          <div key={paymentMethodId}>
            <ul>
              <li className="flex gap-2 my-2">
                <RadioCheckboxInputCustom
                  type="radio"
                  name="saved-payment-methods"
                  checked={selectedPayMethod?.paymentMethodId === paymentMethodId}
                  labelClassName="font-light leading-4"
                  onChange={() => { setSelectedPayMethod( payMethod ) }}
                  label={payMethod.paymentMethodType === `ach`
                    ? `Account ending in ${
                      ( payMethod as BankPaymentMethod )?.accountNumber?.slice( -4 )
                    }`
                    : `Card ending in ${
                      ( payMethod as CardPaymentMethod )?.cardNumber?.slice( -4 )
                    } exp. ${( payMethod as CardPaymentMethod ).expMonth}/20${( payMethod as CardPaymentMethod ).expYear}`}
                />
                <div>
                  {
                    payMethod.paymentMethodType === `card` &&
                  <button
                    type="button"
                    onClick={() => {
                      handleEdit( payMethod )
                    }}
                  >
                    <EditOutlinedIcon className="text-[#373366]" />
                  </button>
                  }
                  <button
                    className="underline text-indigo text-xs md:text-sm"
                    type="button"
                    onClick={() => { deletePaymentMethod( payMethod ) }}
                  >
                    <DeleteOutlineOutlinedIcon className="text-red-700" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )
      })}
      { fetchError && <p className="text-red-700 text-sm my-2">{fetchError}</p> }
      <p className="text-[#373366] underline decoration-[##373366] cursor-pointer mb-5" onClick={() => setShowAddNewPayment( true )}>{`Add New`}</p>
    </>
  )
}

export default PaymentController