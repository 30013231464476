import { checkMark } from 'assets/images'
import { RadioCheckboxInputCustomProps } from '../types'
import { getUniqueIdPrefix } from '../utils'

const RadioCheckboxInputCustom = (
  {
    label,
    id,
    name,
    type = `checkbox`,
    value,
    checked = false,
    defaultChecked = false,
    wrapperClassName = ``,
    required = false,
    labelClassName = ``,
    ...inputProps
  } : RadioCheckboxInputCustomProps ) => {
  const safeUseName = name
    ?.replace( /\s+/g, `__` ) // Convert spaces to double underscores
    .replace( /[^a-zA-Z0-9_]/g, `-` ) // Convert special characters to hyphens
  const uniqueId = id || `${getUniqueIdPrefix()}-${safeUseName}`

  return (
    <div className={`relative  flex pt-1 ${wrapperClassName}`}>
      <input
        id={id ?? uniqueId}
        name={safeUseName}
        required={required}
        type={type}
        defaultChecked={defaultChecked}
        className={`absolute w-[1.5rem] h-[1.5rem] opacity-0 z-50 cursor-pointer`}
        value={value ?? label}
        {...inputProps}
      />
      {label ? (
        <label htmlFor={uniqueId} className={`grid grid-cols-[1.25rem_1fr] items-center gap-4 text-sm cursor-pointer leading-4 ${labelClassName}`}>
          <div className={`${( checked || defaultChecked ) ? `bg-indigo border-indigo` : `bg-white border-[#BCBCBC]`} ${type === `radio` ? `rounded-full` : `` }  border border-[#bcbcbc] w-[1.25rem] h-[1.25rem] relative`}>
            <img className={`${( checked || defaultChecked ) ? `opacity-1` : `opacity-0`} absolute inset-0 m-auto`} src={checkMark} />
          </div>
          <span className="block leading-normal">{type === `checkbox` && <span className="text-canyon font-normal">{`* `}</span>}{label}</span>
        </label>
      ) : (
        <div className={`${( checked || defaultChecked ) ? `bg-indigo border-indigo` : `bg-white border-[#BCBCBC]`} ${type === `radio` ? `rounded-full` : `` }  border border-[#bcbcbc] w-[1.25rem] h-[1.25rem] relative`}>
          <img className={`${( checked || defaultChecked ) ? `opacity-1` : `opacity-0`} absolute inset-0 m-auto`} src={checkMark} />
        </div>
      )}
    </div>
  )
}

export default RadioCheckboxInputCustom
