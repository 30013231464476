import { getUniqueIdPrefix } from '../utils'
import { CheckboxInputProps } from '../types'

const CheckboxInput = ({
  name,
  value,
  onChange,
  required = false,
  label,
  className = `mr-2 focus:outline-none rounded-full`,
  labelClassName = `font-bold text-sm cursor-pointer`,
  containerClassName = `flex items-center justify-center`,
  id,
  reference,
  children,
  ...rest
} : CheckboxInputProps ) => {
  const uniqueId = id || `${getUniqueIdPrefix()}-${name}`

  return (
    <div className={containerClassName}>
      <input
        id={uniqueId}
        name={name}
        type="checkbox"
        checked={value}
        onChange={onChange}
        className={className}
        required={required}
        ref={reference}
        {...rest}
      />

      {required && <span className="text-error font-normal">{`*`}</span>}

      <label htmlFor={uniqueId} className={labelClassName}>
        {children ? children : label}
      </label>
    </div>
  )
}


export default CheckboxInput
