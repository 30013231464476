import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod, PaymentProfileResponse } from './types'
import { useState } from 'react'

import { uuid4 } from "@sentry/utils"
import { EditCardMethodTab, EditBankMethodTab } from './Edit'
import { putOrPostUrlPaymentMethod, serializeBankPaymentMethod, serializeCardPaymentMethod } from './utils'
import { usePayMethodsStore } from 'state'
import { useFetch } from 'hooks'
// import PayMethod from './PayMethod'

export const makeEmptyCardMethod = () : CardPaymentMethod => {
  return {
    cardholderFirstName: ``,
    cardholderLastName: ``,
    cardNumber: ``,
    expMonth: ``,
    expYear: ``,
    ccv: ``,
    state: ``,
    city: ``,
    address: ``,
    zip: ``,
    paymentMethodType: `card`,
    paymentMethodId: uuid4(),
    paymentProfileId: null,
    isEditing: true
  }
}

export const makeEmptyBankMethod = () : BankPaymentMethod => {
  return {
    routingNumber: ``,
    nameOnAccount: ``,
    accountNumber: ``,
    accountType: `checking`,
    paymentMethodType: `ach`,
    paymentMethodId: uuid4(),
    paymentProfileId: null,
    isEditing: true
  }
}

export default function AddNewPayment({ handleCancel, onSuccess }: { handleCancel: () => void, onSuccess?: () => void }) {
  // const [ newPayMethod, setNewPayMethod ] = useState<IPaymentMethod>( makeEmptyCardMethod() )
  const [ showCreditCardForm, setShowCreditCardForm ] = useState( true )
  const [ fetchError, setFetchError ] = useState<string>( `` )
  const [ editingPayMethod, setEditingPayMethod ] = useState<IPaymentMethod | undefined>( makeEmptyCardMethod() )
  const { lazyFetch: savePayMethodToAuthnet, isLoading: isSaveLoading, error: saveError } = useFetch<PaymentProfileResponse>( putOrPostUrlPaymentMethod( editingPayMethod ?? null ), {
    method: `POST`
  })
  const { addPayMethod, setSelectedPayMethod } = usePayMethodsStore()

  const tabBtnStyle = `font-semibold tablink w-1/2 text-center border-skyBlue2 rounded rounded-b-none border p-4 -mb-[1px]`
  const tabContentStyle = `border-skyBlue2 border`

  async function savePaymentMethod() {
    setFetchError( `` )

    let serializedPayMethod
    if ( editingPayMethod?.paymentMethodType === `card` ) {
      serializedPayMethod = serializeCardPaymentMethod( editingPayMethod as CardPaymentMethod )
    } else if ( editingPayMethod?.paymentMethodType === `ach` ) {
      serializedPayMethod = serializeBankPaymentMethod( editingPayMethod as BankPaymentMethod )
    }

    const data = await savePayMethodToAuthnet( serializedPayMethod )

    if ( data?.customerPaymentProfileId && data?.messages?.resultCode?.toLowerCase() === `ok` ) {
      const updatedPayMethod = {
        ...editingPayMethod,
        paymentProfileId: data.customerPaymentProfileId,
        isEditing: false
      }
      addPayMethod( updatedPayMethod as IPaymentMethod )

      setSelectedPayMethod( updatedPayMethod as IPaymentMethod )

      if ( onSuccess ) onSuccess()

    } else {
      return setFetchError( data?.messages?.message?.[0]?.text ?? `We are unable to process your payment method at this time` )
    }
  }

  return (
    <div className={`my-6`}>
      <div className="tab text-sm md:text-base flex gap-1 w-full">
        <button
          type="button"
          className={`${tabBtnStyle} ${showCreditCardForm ? `bg-white border-b-0` : `bg-skyBlue4`}`}
          onClick={() => {
            setShowCreditCardForm( true )
            setEditingPayMethod( makeEmptyCardMethod() )
          }}
        >
          {`Pay by credit or debit card`}
        </button>
        <button
          type="button"
          className={`${tabBtnStyle} ${showCreditCardForm ? `bg-skyBlue4` : `bg-white border-b-0`}`}
          onClick={() => {
            setShowCreditCardForm( false )
            setEditingPayMethod( makeEmptyBankMethod() )
          }}
        >
          {`Pay by bank account`}
        </button>
      </div>
      <div className={`${tabContentStyle}`}>
        {showCreditCardForm ? (
          <EditCardMethodTab
            payMethod={editingPayMethod as CardPaymentMethod}
            setPayMethod={setEditingPayMethod}
            onSave={savePaymentMethod}
            newPayment
            onCancel={handleCancel}
            displayLoading={isSaveLoading}
            displayError={fetchError}
          />
        ) : (
          <EditBankMethodTab
            payMethod={editingPayMethod as BankPaymentMethod}
            setPayMethod={setEditingPayMethod}
            onSave={savePaymentMethod}
            onCancel={handleCancel}
            displayLoading={isSaveLoading}
            displayError={fetchError || ( saveError?.toString() ?? `` )}
          />
        )}
      </div>
    </div>
  )
}

