import { RadioCheckboxInputCustom } from 'components/inputs/components'
import { NavButton } from 'components/navButton'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import { postPrescriptionConfirmation } from 'modules/doctorRefer/fetchPromises'
import React from 'react'
import { capitalizeWords } from 'utils/utils'

interface PrescriptionConfirmationProps {
  nextPage: () => void
  patientId: number
  invoice: { details: { is_sensor: boolean; patient_owes: number }[], pay_later_eligible_flag: boolean }
  items: []
}

export default function PrescriptionConfirmation({nextPage, patientId, invoice, items}: PrescriptionConfirmationProps ) {
  const [ loading, setLoading ] = React.useState<boolean>( false )
  const [ submitError, setSubmitError ] = React.useState<string>( `` )
  const [ provider, hasReader, setHasReader ] = useDoctorReferStore( ( state: any ) => [ state.refer.provider, state.refer.hasReader, state.setHasReader ] )
  const noReceiverInOrder = invoice.details.filter( item => item.is_sensor === false ).length === 0

  const handleOnSubmit = ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault()
    if ( hasReader === null ) return setSubmitError( `Please select an option.` );
    ( event.target as HTMLFormElement ).formData
    setLoading( true )

    const abortController = new AbortController()
    const {signal} = abortController

    postPrescriptionConfirmation( signal, patientId, hasReader ).then( response => {
      if ( response && response?.meta?.status?.toLowerCase() === `ok` ) return nextPage()
      setSubmitError( `There was an error submitting your prescription. Please try again.` )
    })
      .catch( () => {
        setSubmitError( `There was an error submitting your prescription. Please try again.` )
      })
      .finally( () => setLoading( false ) )
  }

  const receiverName =
      items.map( ( item: any ) => {
        if ( item?.is_sensor === false ) {
          return capitalizeWords( item?.item_description.toLowerCase() )
        }
      }).filter( ( name: string | undefined ) => name !== undefined )

  if ( loading ) {
    window.scrollTo({
      top: 0,
      behavior: `smooth`
    })

    return (
      <div className="w-full h-full bg-white opacity-75 absolute inset-0 z-100 flex justify-center items-center">
        <span>{`Loading...`}</span>
      </div>
    )
  }

  return (
    <>
      <div className="py-8 pt-0 text-xs px-8 lg:px-0">
        <p className="font-sourceserif4 text-2xl md:text-3xl text-mb-4 pb-4">
          {`Confirm Your Prescription Details`}
        </p>
        <p className="font-sourceserif4 text-lg md:text-xl mb-4 leading-5">
          {`Do you already have a CGM receiver/reader?`}
        </p>
        <form onSubmit={handleOnSubmit} className="flex flex-col space-y-4 lg:space-y-6 mb-8">
          <RadioCheckboxInputCustom
            type="radio"
            label={`Yes, I already have a receiver/reader  - do not send another one.`}
            name={`cgm`}
            labelClassName="leading-4"
            onChange={() => {
              setHasReader( true )
            }}
            defaultChecked={hasReader === true}
          />
          <RadioCheckboxInputCustom
            type="radio"
            label={`No, I do not have a receiver/reader.`}
            name={`cgm`}
            labelClassName="leading-4"
            onChange={() => {
              setHasReader( false )
            }}
            defaultChecked={hasReader === false}
          />
          {
            ( hasReader && !noReceiverInOrder && provider !== `undefined` ) && (
              <p className="text-xs text-greenAlert font-semibold mb-4">
                {`We will not send ${receiverName[0]} with this order.`}
              </p>
            )
          }
          <NavButton
            options={
              {
                buttonText: `Next`,
                className: `bg-[#83c6d6] rounded py-2 px-4 w-full text-md disabled:bg-[#e0e0e0] disabled:cursor-not-allowed disabled:text-gray-800 text-base md:text-lg font-normal lg:w-1/2`
              }
            }
          />
          { submitError && <p className="text-error text-xs font-semibold">{submitError}</p> }
        </form>
        <div className="text-xs flex justify-center items-center gap-1 lg:justify-start md:text-base">
          <span>
            {`Your order will be fulfilled by `}
          </span>
          <svg
            width="125"
            height="15"
            viewBox="0 0 125 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_284_5457)">
              <path d="M74.0422 1.51141C72.4922 -1.06047 66.1161 -0.31984 60.0116 3.50263C55.2085 6.5067 51.6605 12.3425 51.8601 15.0001C52.652 12.255 55.832 7.69188 60.8578 4.73024C65.4747 2.02756 70.0622 1.22329 71.1267 2.99356C72.1912 4.76383 69.3246 8.41926 64.7167 11.1467C64.0361 11.5541 59.7746 13.5135 59.1484 12.1622C58.6834 11.1626 61.1509 8.91684 64.7532 7.11299C65.9771 6.4846 67.2572 5.96934 68.5755 5.57251C66.8866 5.55484 64.5305 6.1735 62.1334 7.3746C58.0795 9.40117 55.4437 12.3292 56.2454 13.9059C57.0471 15.4827 60.9852 15.1088 65.032 13.0769C66.1321 12.4777 67.193 11.8113 68.2103 11.0813C72.8005 7.66714 75.3579 3.69884 74.0413 1.5123H74.0422V1.51141Z" fill="#A67EB1" />
              <path d="M74.9712 11.8909L75.0567 11.5091C76.1399 11.3818 76.3537 11.3111 76.5247 10.5051L78.064 3.34974C78.2492 2.48714 77.9642 2.45886 77.0235 2.34573L77.1091 1.96393H80.7149C83.5226 1.96393 85.2044 3.50529 85.2044 5.95167C85.2044 9.60003 82.4822 11.8909 79.0046 11.8909H74.9712ZM79.1614 11.4242C81.7696 11.4242 83.7649 9.28893 83.7649 5.95167C83.7649 3.77397 82.7673 2.43058 80.5296 2.43058H79.6032L77.8929 10.4202C77.7077 11.2545 78.064 11.4242 78.9476 11.4242H79.1614Z" fill="#222222" />
              <path d="M86.9299 5.6123L86.0605 5.17394L86.1033 5.00424L88.3409 4.39618H88.4834L87.0012 10.2505C86.9299 10.5192 86.8729 10.7596 86.8729 10.8727C86.8729 11.0141 86.9442 11.0848 87.0725 11.0848C87.3433 11.0848 87.7138 10.802 88.3552 10.0243L88.569 10.1798C87.6996 11.4667 87.158 12.0323 86.4881 12.0323C85.9465 12.0323 85.6472 11.6505 85.6472 11.0707C85.6472 10.7455 85.7042 10.3919 85.8467 9.85459L86.9299 5.6123ZM87.4858 2.43059C87.4858 1.93566 87.8991 1.52557 88.3837 1.52557C88.8683 1.52557 89.2103 1.89324 89.2103 2.34575C89.2103 2.84068 88.797 3.25077 88.3124 3.25077C87.8278 3.25077 87.4858 2.8831 87.4858 2.43059Z" fill="#222222" />
              <path d="M93.7292 8.66677C92.9169 10.4485 91.6484 12.0323 90.5224 12.0323C89.5675 12.0323 89.0544 11.2404 89.0544 9.96774C89.0544 6.984 91.2921 4.39621 93.3444 4.39621C93.9715 4.39621 94.3706 4.58004 94.7554 4.96185L95.7816 4.28308H96.0666L95.5678 5.86687L94.4989 10.2506C94.4276 10.5192 94.3706 10.7596 94.3706 10.8728C94.3706 11.0142 94.4419 11.0849 94.5701 11.0849C94.8409 11.0849 95.2115 10.8021 95.8529 10.0243L96.0666 10.1799C95.1972 11.4667 94.6557 12.0323 93.9858 12.0323C93.4442 12.0323 93.1734 11.6505 93.1734 11.0707C93.1734 10.6465 93.4584 9.41624 93.7292 8.66677ZM94.5274 5.51334C94.3278 5.06083 93.9145 4.79215 93.4157 4.79215C91.7482 4.79215 90.2944 7.45065 90.2944 9.96774C90.2944 10.7031 90.5224 11.1414 90.9785 11.1414C92.1045 11.1414 93.6295 9.00615 94.5274 5.51334Z" fill="#222222" />
              <path d="M98.4049 7.93143C99.2173 6.14968 100.799 4.3962 101.925 4.3962C102.88 4.3962 103.45 5.18809 103.45 6.46078C103.45 9.44451 101.013 12.0323 98.9607 12.0323C97.8633 12.0323 96.8086 11.3818 96.5806 10.3213L98.6757 1.99224L97.7065 1.53973L97.7493 1.37004L100.058 0.818542H100.201L98.4049 7.93143ZM97.6352 10.8727C97.8063 11.396 98.3906 11.6081 98.8895 11.6081C100.642 11.6081 102.182 8.86474 102.196 6.5739C102.196 5.79615 102.011 5.28708 101.469 5.28708C100.343 5.28708 98.3051 7.53549 97.6352 10.8727Z" fill="#222222" />
              <path d="M105.056 8.66674C105.041 8.86472 105.027 9.07683 105.027 9.26066C105.027 10.5475 105.583 11.297 106.509 11.297C107.336 11.297 108.02 10.7879 108.718 9.88286L108.975 10.0667C108.077 11.3677 107.179 12.0323 106.025 12.0323C104.599 12.0323 103.787 11 103.787 9.48692C103.787 7.02639 105.668 4.39618 107.849 4.39618C108.989 4.39618 109.517 5.01838 109.517 5.76785C109.517 7.59203 107.265 8.20009 105.056 8.66674ZM105.098 8.36978C107.279 7.87485 108.362 7.12538 108.362 5.69715C108.362 5.24464 108.148 4.83455 107.593 4.83455C106.367 4.83455 105.369 6.60217 105.098 8.36978Z" fill="#222222" />
              <path d="M111.102 10.0526C111.031 10.3495 110.988 10.5758 110.988 10.7596C110.988 10.9859 111.116 11.1273 111.344 11.1273C111.872 11.1273 112.285 10.7738 113.097 9.85462L113.311 10.0243C112.328 11.3818 111.558 12.0323 110.76 12.0323C110.133 12.0323 109.748 11.6222 109.748 10.9859C109.748 10.6748 109.791 10.3495 109.905 9.91118L111.088 5.23053H110.147L110.218 4.96185C111.245 4.62247 111.886 3.91542 112.599 2.9397H112.898L112.456 4.69317H114.067L113.924 5.23053H112.328L111.102 10.0526Z" fill="#222222" />
              <path d="M115.023 8.66674C115.009 8.86472 114.995 9.07683 114.995 9.26066C114.995 10.5475 115.551 11.297 116.477 11.297C117.304 11.297 117.988 10.7879 118.686 9.88286L118.943 10.0667C118.045 11.3677 117.147 12.0323 115.993 12.0323C114.567 12.0323 113.755 11 113.755 9.48692C113.755 7.02639 115.636 4.39618 117.817 4.39618C118.957 4.39618 119.484 5.01838 119.484 5.76785C119.484 7.59203 117.232 8.20009 115.023 8.66674ZM115.066 8.36978C117.247 7.87485 118.33 7.12538 118.33 5.69715C118.33 5.24464 118.116 4.83455 117.56 4.83455C116.335 4.83455 115.337 6.60217 115.066 8.36978Z" fill="#222222" />
              <path d="M123.002 10.4202C123.002 9.35965 120.422 8.07282 120.422 6.48904C120.422 5.32948 121.42 4.39618 122.788 4.39618C123.871 4.39618 124.527 4.87697 124.527 5.59816C124.527 5.97996 124.185 6.24864 123.9 6.37591H123.843C123.529 5.56988 123.002 4.93353 122.204 4.93353C121.562 4.93353 121.22 5.32948 121.22 5.9234C121.22 6.87084 123.843 8.29908 123.843 9.79802C123.843 11.1838 122.674 12.0323 121.391 12.0323C120.109 12.0323 119.282 11.3535 119.282 10.5333C119.282 10.1233 119.524 9.75559 119.895 9.5859H119.966C120.251 10.703 120.907 11.5091 121.862 11.5091C122.617 11.5091 123.002 11.1131 123.002 10.4202Z" fill="#222222" />
              <path d="M7.75589 8.50059H3.7082L2.5395 10.4096C2.35422 10.7207 2.25445 10.947 2.25445 11.1167C2.25445 11.456 2.63927 11.5692 3.43741 11.5975L3.36614 11.9793H0.4729L0.544163 11.5975C0.957483 11.555 1.27104 11.456 1.49908 11.0742L6.60145 2.83007L6.40191 1.92505H7.77015L9.59446 11.0742C9.66572 11.456 9.93652 11.555 10.3213 11.5975L10.2501 11.9793H6.67271L6.74397 11.5975C7.52785 11.5692 8.18347 11.5267 8.18347 10.7773C8.18347 10.6783 8.16921 10.551 8.14071 10.4238L7.75589 8.50059ZM3.979 8.04808H7.67038L6.78673 3.48055L3.979 8.04808Z" fill="#222222" />
              <path d="M12.0014 8.75512C11.9871 8.95309 11.9729 9.16521 11.9729 9.34904C11.9729 10.6359 12.5287 11.3853 13.4551 11.3853C14.2818 11.3853 14.9659 10.8763 15.6643 9.97124L15.9208 10.1551C15.0229 11.456 14.125 12.1207 12.9705 12.1207C11.5453 12.1207 10.7329 11.0884 10.7329 9.5753C10.7329 7.11477 12.6142 4.48456 14.7949 4.48456C15.935 4.48456 16.4624 5.10676 16.4624 5.85623C16.4624 7.68041 14.2105 8.28847 12.0014 8.75512ZM12.0441 8.45816C14.2248 7.96323 15.3079 7.21376 15.3079 5.78552C15.3079 5.33301 15.0942 4.92293 14.5383 4.92293C13.3126 4.92293 12.3149 6.69054 12.0441 8.45816Z" fill="#222222" />
              <path d="M18.8799 7.12891L18.2956 8.86825C19.5356 6.30874 20.5617 4.48456 21.5594 4.48456C22.0297 4.48456 22.2863 4.82394 22.2863 5.24817C22.2863 5.84209 21.93 6.15319 21.3884 6.40773H21.2744C21.2744 6.09662 21.2173 5.82795 20.8753 5.82795C20.1057 5.82795 18.5094 8.26019 17.697 11.9793H16.457L18.0391 5.70068L17.1697 5.26231L17.2124 5.09262L19.45 4.48456H19.5926L18.8799 7.12891Z" fill="#222222" />
              <path d="M21.5718 9.51873C21.5718 7.27032 23.2536 4.48456 25.534 4.48456C26.9449 4.48456 27.7858 5.432 27.7858 7.08649C27.7858 9.3349 26.1041 12.1207 23.8237 12.1207C22.4127 12.1207 21.5718 11.1732 21.5718 9.51873ZM26.5316 6.95922C26.5316 5.77138 26.2466 4.8805 25.3914 4.8805C23.9377 4.8805 22.826 7.68041 22.826 9.646C22.826 10.8338 23.111 11.7247 23.9662 11.7247C25.4199 11.7247 26.5316 8.92481 26.5316 6.95922Z" fill="#222222" />
              <path d="M30.9338 5.31889L29.8934 9.84399C29.024 13.6338 28.1261 14.9065 26.5583 14.9065C25.8457 14.9065 25.3184 14.5812 25.3184 14.0156C25.3184 13.5065 25.7317 13.2802 26.1022 13.1954H26.2305C26.3018 13.9873 26.6438 14.4964 27.0714 14.4964C27.5702 14.4964 27.9123 14.1994 28.5394 10.9046L29.5656 5.31889H28.4539L28.5394 4.97951L29.6796 4.72497C30.1499 2.60383 31.4897 0.906921 33.2712 0.906921C33.8413 0.906921 34.4114 1.11904 34.782 1.40185L35.9079 0.906921H36.0504L33.713 10.3389C33.6418 10.6076 33.5848 10.848 33.5848 10.9611C33.5848 11.1025 33.656 11.1732 33.7843 11.1732C34.0551 11.1732 34.4257 10.8904 35.067 10.1127L35.2808 10.2682C34.4114 11.555 33.8698 12.1207 33.2 12.1207C32.6584 12.1207 32.3591 11.7389 32.3591 11.1591C32.3591 10.8339 32.4303 10.4803 32.5586 9.94298L34.4542 2.37758C34.2546 1.78366 33.7701 1.34529 33.1714 1.34529C31.903 1.34529 31.2474 2.60383 31.0051 4.78154H32.7866L32.6584 5.31889H30.9338Z" fill="#222222" />
              <path d="M35.7939 9.51873C35.7939 7.27032 37.4757 4.48456 39.7561 4.48456C41.1671 4.48456 42.008 5.432 42.008 7.08649C42.008 9.3349 40.3262 12.1207 38.0458 12.1207C36.6348 12.1207 35.7939 11.1732 35.7939 9.51873ZM40.7538 6.95922C40.7538 5.77138 40.4687 4.8805 39.6136 4.8805C38.1598 4.8805 37.0482 7.68041 37.0482 9.646C37.0482 10.8338 37.3332 11.7247 38.1884 11.7247C39.6421 11.7247 40.7538 8.92481 40.7538 6.95922Z" fill="#222222" />
              <path d="M45.7172 5.26231L45.7742 5.09262L47.6555 4.48456H47.6983L48.0973 10.8056L49.6509 8.76926C50.3777 7.80768 50.6913 7.17134 50.6913 6.3653C50.6913 5.61583 50.1782 5.21989 49.3231 5.30473V5.19161C49.5653 4.89465 49.9929 4.48456 50.5488 4.48456C51.4182 4.48456 51.6605 5.1209 51.6605 5.68654C51.6605 6.61984 51.1331 7.52486 50.0214 8.93895L47.513 12.1207H47.0712L46.7861 8.54301L44.0211 12.1207H43.5793L43.0662 5.62997L42.2253 5.26231L42.2824 5.09262L44.1637 4.48456H44.2064L44.634 10.749L46.7434 8.00565L46.5581 5.62997L45.7172 5.26231Z" fill="#222222" />
            </g>
            <defs>
              <clipPath id="clip0_284_5457">
                <rect
                  width="124.054"
                  height="15"
                  fill="white"
                  transform="translate(0.4729 0.00012207)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </>
  )
}