/* eslint-disable object-curly-newline */
import { Formatters, TextInput, Validators } from "components/inputs/components"
import StateDropdownInput from 'components/inputs/components/StateDropdownInput'
import { regexErrors } from 'components/inputs/constants'
import LoadingSpinner from 'components/LoadingSpinner'
import useValidate from 'hooks/useValidate'
import * as React from 'react'
import { useRef } from 'react'
import { useAddressStore } from 'state'
import { reportToSentry } from 'utils/reportToSentry'
import { TypeAheadTextInput } from '../components'
import { fetchAddressValidation, mapFootnotes } from '../components/utils'
import { useUpdateAddress } from '../hooks/useUpdateAddress'
import { serializeFromVerifiedAddress, serializeToOriginalAddress } from '../serializers'
import { useAddressStyleStore } from '../style'

type EditAddressProps = {
  shouldSkipValidation: boolean;
  onSuccessfulConfirm?: () => void;
  hideAddressConfirm?: boolean;
}

export default function EditAddress({shouldSkipValidation, hideAddressConfirm, onSuccessfulConfirm } : EditAddressProps ) : JSX.Element {

  const [ address, setAddress ] = useAddressStore( state => [ state.address, state.setAddress ] )
  const initalAddress = useRef( address )

  const refs = {
    firstName: React.useRef<HTMLInputElement>( null ),
    lastName: React.useRef<HTMLInputElement>( null ),
    street: React.useRef<HTMLInputElement>( null ),
    street2: React.useRef<HTMLInputElement>( null ),
    city: React.useRef<HTMLInputElement>( null ),
    state: React.useRef<HTMLInputElement>( null ),
    zipCode: React.useRef<HTMLInputElement>( null )
  } as Record<string, React.RefObject<HTMLInputElement> | React.RefObject<HTMLSelectElement>>

  const { evaluateErrorsOnChange, // Runs validation behind the scenes with onChange event
    evaluateErrorsOnSubmit, // Runs total validation and focuses on errored inputs with onSubmit event
    evaluateErrorsOnBlur, // Runs validation behind the scenes with onBlur event
    formErrors, // Object of errors for each field,
    setFormError, // Function to set a specific error for state dropdown
    shouldHideErrors,
    errorsPresent // Boolean that returns true if there are errors
  } = useValidate( refs )

  const { setCurrentView, setVerifiedAddress, setAddressWebConfirmed, setAddressPatientConfirmed, setSmartyValidationMessage, addressPatientConfirmed } = useAddressStore()

  const [ isLoading, setIsLoading ] = React.useState<boolean>( false )
  const [ submitError, setSubmitError ] = React.useState<string>( `` )

  const { updatePatientAddress, isUpdatingPatientAddress } = useUpdateAddress()

  const { styleOverrides } = useAddressStyleStore()
  const { ctaStyle, textInputClassName, buttonClassName } = styleOverrides

  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    // Evaluate errors on change
    evaluateErrorsOnChange( event )

    const { name, value } = event.currentTarget

    // Patient is attempting to change their address manually
    // This flag below lets internal know that the patient address was not verified by the autocomplete api
    if ( name !== `firstName` && name !== `lastName` ) setAddressPatientConfirmed( false )
    setAddress({
      ...address,
      [name]: value ?? ``
    })
  }

  // There is a special case for state, we need to revalidate the state on dropdown selection
  const validateStateOnBlur = () => {
    const isValidState = Validators.state( address.state )
    setFormError( `state`, isValidState ? `` : regexErrors.state )
  }

  const handleOnSave = () => {
    const hasError = evaluateErrorsOnSubmit()
    if ( hasError ) return

    setAddressWebConfirmed( false ) // Resets this flag - This lets internal users know that the address was not verified by the verification api

    if ( shouldSkipValidation ) {
      if ( onSuccessfulConfirm ) onSuccessfulConfirm()

      return setCurrentView( `complete` )
    }

    setIsLoading( true )

    fetchAddressValidation( serializeToOriginalAddress( address ) )
      .then( ( response ) => {
        if ( response ) {
          setSubmitError( `` )

          if ( response?.length === 0 ) return setCurrentView( `confirm` )

          const data = response[0]

          // get the usps verified address components
          if ( data?.components ) setVerifiedAddress( serializeFromVerifiedAddress( data?.delivery_line_1, data.components ) )

          // H# means we are expecting a street 2. Refer to https://www.smarty.com/docs/cloud/us-street-api for more info
          if ( data?.analysis?.footnotes?.includes( `H#` ) ) return setCurrentView( `confirm` ) // missing secondary address, send to confirm

          const error = mapFootnotes( data.analysis.footnotes ?? `` )

          // AABB signifies a valid address and if we have a footnote that maps to an error we have setup send them to the verify step
          if ( data?.analysis?.dpv_footnotes !== `AABB` || ( data?.analysis?.footnotes && error ) ) {
            if ( data?.analysis?.footnotes ) setSmartyValidationMessage( mapFootnotes( data.analysis.footnotes ) )

            return setCurrentView( `verify` )
          }
          setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api

          if ( hideAddressConfirm ) updatePatientAddress( addressPatientConfirmed, true )

          return setCurrentView( `complete` )
        }
        setSubmitError( `Oops... we encountered an error attempting to update your address. Please try again or reach out to customer service at (866) 616-1687 if this issue persists.` )
        setCurrentView( `confirm` )
      })
      .catch( ( error ) => {
        setSubmitError( `Oops... we encountered an error attempting to update your address. Please try again or reach out to customer service at (866) 616-1687 if this issue persists.` )
        setCurrentView( `confirm` )

        reportToSentry( new Error( `Smarty USPS Validation Error`, { cause: error }), {
          address: JSON.stringify( address )
        })
      })
      .finally( () => {
        setIsLoading( false )
        if ( onSuccessfulConfirm ) onSuccessfulConfirm()
      })
  }

  const labelClassName = `ml-4 font-light`

  return (
    <div className="flex flex-col mb-4 text-left gap-2">
      <TextInput
        label="First Name"
        labelClassName={labelClassName}
        className={textInputClassName}
        name="firstName"
        value={address.firstName}
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors?.firstName ) ? formErrors.firstName : ``}
        reference={refs.firstName as React.RefObject<HTMLInputElement>}
        required
        onBlur={evaluateErrorsOnBlur}
      />
      <TextInput
        label="Last Name"
        labelClassName={labelClassName}
        name="lastName"
        value={address.lastName}
        className={textInputClassName}
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors?.lastName ) ? formErrors.lastName : ``}
        reference={refs.lastName as React.RefObject<HTMLInputElement>}
        required
        onBlur={evaluateErrorsOnBlur}
      />
      <TypeAheadTextInput
        label="Street Address"
        labelClassName={labelClassName}
        className={textInputClassName}
        name="street"
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors.street ) ? formErrors.street : ``}
        value={address.street}
        required
        onBlur={evaluateErrorsOnBlur}
        ref={refs.street}
      />
      <TextInput
        label="Apt, suite, unit, building, floor, etc."
        labelClassName={labelClassName}
        className={textInputClassName}
        name="street2"
        value={address.street2}
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors.street2 ) ? formErrors.street2 : ``}
        reference={refs.street2 as React.RefObject<HTMLInputElement>}
        onBlur={evaluateErrorsOnBlur}
      />
      <div className="mb-8">
        <TextInput
          label="City"
          labelClassName={labelClassName}
          className={textInputClassName}
          name="city"
          value={address.city}
          onChange={handleChange}
          errorMessage={( !shouldHideErrors && formErrors.city ) ? formErrors.city : ``}
          reference={refs.city as React.RefObject<HTMLInputElement>}
          required
          onBlur={evaluateErrorsOnBlur}
        />

        <StateDropdownInput
          ref={refs.state}
          value={address.state}
          className={textInputClassName}
          labelClassName={labelClassName}
          errorMessage={( !shouldHideErrors && formErrors.state ) ? formErrors.state : ``}
          onChange={handleChange}
          onDropdownClose={validateStateOnBlur}
          onBlur={validateStateOnBlur}
          required
        />

        <TextInput
          label="Zip Code"
          labelClassName={labelClassName}
          className={textInputClassName}
          name="zipCode"
          value={address.zipCode}
          onChange={handleChange}
          formatter={{
            function: Formatters.zipCode
          }}
          errorMessage={( !shouldHideErrors && formErrors.zipCode ) ? formErrors.zipCode : ``}
          reference={refs.zipCode as React.RefObject<HTMLInputElement>}
          required
          onBlur={evaluateErrorsOnBlur}
        />
      </div>
      {submitError && <p className="text-error text-center mb-2">{submitError}</p>}
      {( isLoading || isUpdatingPatientAddress ) && <p className="text-center">{`Verifying your address...`}</p>}
      {errorsPresent && <p className="text-center text-error mb-2">{`Please ensure all fields are complete and correct`}</p>}
      <div className="flex flex-col items-center gap-4">
        <button className={buttonClassName} onClick={handleOnSave} disabled={errorsPresent || isLoading}>
          {`Confirm`}
        </button>
        <p
          className={ctaStyle} onClick={() => {
            setAddress( initalAddress.current )
            setCurrentView( `complete` )
          }}
        >
          {`Cancel`}
        </p>
      </div>

      {isLoading && <LoadingSpinner width="4rem" height="4rem" />}
    </div>
  )
}
