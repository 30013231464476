import { reportToSentry } from 'utils/reportToSentry'
import { useAddressStore } from 'state/address'
import { serializeToPatientsUpdateAddress } from '../serializers'
import { useState } from 'react'
import { getAuthHeader } from 'utils/getHash'

export function useUpdateAddress( hideAddressConfirm: boolean ) {
  const { address, setIsAddressComplete } = useAddressStore()

  const [ isUpdatingPatientAddress, setIsUpdatingPatientAddress ] = useState<boolean>( false )

  const updatePatientAddress = ( addressPatientConfirmed = false, addressWebConfirmed = false ) => {
    setIsUpdatingPatientAddress( true )
    const authorization = getAuthHeader()

    fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-address`, {
      method: `POST`,
      headers: {
        'Authorization': authorization
      },
      body: JSON.stringify({
        addressType: `shipping`,
        addressWebConfirmed: addressWebConfirmed,
        addressPatientConfirmed: addressPatientConfirmed,
        ...serializeToPatientsUpdateAddress( address )
      })
    })
      .then( async ( response ) => {
        const responseData = await response.json()
        if ( responseData && responseData?.meta?.status === `ok` ) return setIsAddressComplete( hideAddressConfirm )
      })
      .catch( err => {
        console.error( `Something happened updating patient address` )
        reportToSentry( new Error( `Something happened updating patient address` ), {
          err
        })

        setIsAddressComplete( hideAddressConfirm )
      })
      .finally( () => setIsUpdatingPatientAddress( false ) )
  }

  return {
    updatePatientAddress,
    isUpdatingPatientAddress
  }
}