import React from 'react'
import Tooltip from 'components/tooltip'
import { NotInterestedTooltip } from 'modules/aobPayments/components/pricingEstimate/tooltips'
import PropTypes from 'prop-types'
import { NOT_INTERESTED_PATH } from 'modules/doctorRefer/constants'
import getHash from 'utils/getHash'
type DeferSuppliesProps = { setNotInterested: React.Dispatch<React.SetStateAction<boolean>> }

export default function DeferSupplies({ setNotInterested }:DeferSuppliesProps ) {
  return (
    <div className="relative">
      <Tooltip
        tooltipContent={<NotInterestedTooltip setNotInterested={setNotInterested} redirectPath={`${NOT_INTERESTED_PATH}?sgh=${getHash()}`} />}
        positioning="left-0 right-0 -top-48"
      >
        <div className="p-8 text-center">
          <p className="text-xs">
            {`Not interested in receiving supplies through insurance at this time?`}
            { ` ` }
            <span className="text-xs underline underline-offset-2 cursor-pointer">{`Click here.`}</span>
          </p>
        </div>
      </Tooltip>
    </div>
  )
}

DeferSupplies.propTypes = {
  setNotInterested: PropTypes.func
}