
import { SUMMARY_PATH } from 'modules/doctorRefer/constants'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import getHash from 'utils/getHash'
import ConfirmShippingAndPayment from "./ConfirmShippingAndPayment"
import Header from "./Header"
import ItemsToShip from "./ItemsToShip"
import PageTitle from "./PageTitle"

export default function Checkout({ invoice, nextPage, prevPage }: any ) {
  const navigate = useNavigate()
  const [
    pageLoadCount,
    hasAcceptedTerms,
    hasReader
  ] = useDoctorReferStore( ( state: any ) => [
    state.refer.pageLoadCount,
    state.refer.hasAcceptedTerms,
    state.refer.hasReader
  ] )

  if ( !hasAcceptedTerms && hasReader === null || pageLoadCount > 1 ) {
    navigate( `${SUMMARY_PATH}?sgh=${getHash()}` )
  }

  return (
    <>
      <Header prevPage={prevPage} />
      <PageTitle className="lg:hidden mt-8" />
      <div className="flex flex-wrap flex-col-reverse lg:flex-row lg:flex-nowrap lg:justify-between mb-8 lg:max-w-[1100px] mx-auto">
        <ConfirmShippingAndPayment
          invoice={invoice}
          nextPage={nextPage}
        />
        <ItemsToShip invoice={invoice} />
      </div>
    </>
  )
}

Checkout.propTypes = {
  invoice: PropTypes.shape({
    patient: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  })
}
