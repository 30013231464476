import { SmartyAddressAutocomplete, SmartyAddressValidation } from "types/address"

export type AutoCompleteResponse = {
  suggestions: SmartyAddressAutocomplete[]
}

export const fetchAutoCompleteAddresses = async ( search: string ) : Promise<AutoCompleteResponse> => {
  const response = await fetch( `${process.env.REACT_APP_AUTOCOMPLETE_URL}/lookup?key=${process.env.REACT_APP_SMARTY_EMBEDDED_KEY}&search=${encodeURI( search )}`, {
    method: `GET`,
    headers: {
      'Content-Type': `application/json`
    }
  })

  return await response.json()
}

export const fetchAddressValidation = async ( address: SmartyAddressValidation ) : Promise<any> => {
  const searchParams = new URLSearchParams( address )
  const response = await fetch( `${process.env.REACT_APP_SMARTY_US_VALIDATION_URL}/street-address?key=${process.env.REACT_APP_SMARTY_EMBEDDED_KEY}&${searchParams.toString()}`, {
    method: `GET`,
    headers: {
      'Content-Type': `application/json`
    }
  })

  return await response.json()
}

export const mapFootnotes = ( footnotes: string ) => {
  switch ( true ) {
  case footnotes.includes( `A#` ): return `The address was found to have a different 5-digit ZIP Code than the one submitted.`
  case footnotes.includes( `B#` ): return `The spelling of the city name and/or state abbreviation in the submitted address was found to be different than the standard spelling.`
  case footnotes.includes( `C#` ): return `The ZIP Code in the submitted address could not be found because neither a valid city and state, nor valid 5-digit ZIP Code was present.`
  case footnotes.includes( `D#` ): return `This address is not present in the USPS data.`
  case footnotes.includes( `E#` ): return `Multiple records were returned, with the same 5-digit ZIP Code.`
  case footnotes.includes( `F#` ): return `Address not found.`
  case footnotes.includes( `I#` ): return `More than one ZIP+4 Code was found to satisfy the address as submitted. The submitted address did not contain sufficiently complete or correct data to determine a single ZIP+4 Code.`
  case footnotes.includes( `J#` ): return `The input contained two addresses.`
  case footnotes.includes( `K#` ): return `Although the address as submitted is not valid, we were able to find a match by changing the cardinal direction (North, South, East, West).`
  case footnotes.includes( `L#` ): return `An address component (i.e., directional or suffix only) was added, changed, or deleted in order to achieve a match.`
  case footnotes.includes( `M#` ): return `Incorrect street name spelling.`
  case footnotes.includes( `N#` ): return `Fix street or secondary abbreviations.`
  case footnotes.includes( `O#` ): return `More than one ZIP+4 Code was found to satisfy the address as submitted.`
  case footnotes.includes( `S#` ): return `The secondary information (apartment, suite, etc.) was not recognized as part of the USPS data.`
  case footnotes.includes( `U#` ): return `The city name in the submitted address is an alternate city name that is not accepted by the US Postal Service.`
  case footnotes.includes( `V#` ): return `The city and state in the submitted address could not be verified as corresponding to the given 5-digit ZIP Code.`
  default:
    return `We couldn’t verify your address. Please make sure it’s correct before you save it.`
  }
}

export const buildAddressString = ( suggestion: SmartyAddressAutocomplete ) : string => {
  let whiteSpace = ``
  if ( suggestion.secondary ) {
    if ( suggestion.entries > 1 ) {
      suggestion.secondary += ` (` + suggestion.entries + ` entries)`
    }
    whiteSpace = ` `
  }

  return suggestion.street_line + whiteSpace + suggestion.secondary + ` ` + suggestion.city + `, ` + suggestion.state + ` ` + suggestion.zipcode
}