import { RadioInput } from "components/inputs/components"
import AddressP from "../components/AddressP"
import { useAddressStore } from "state"
import { useAddressStyleStore } from "../style"
import HighlightP from "../components/HighlightP"
import { useUpdateAddress } from "../hooks/useUpdateAddress"

export default function VerifyAddress({ hideAddressConfirm } : { hideAddressConfirm?: boolean }) : JSX.Element {
  const { address, setAddress, verifiedAddress, setCurrentView, setAddressWebConfirmed, setSmartyValidationMessage, addressPatientConfirmed } = useAddressStore()
  const [ selected, setSelected ] = useAddressStore( state => [ state.userConfirmedAddress, state.setUserConfirmedAddress ] )

  const selectOriginal = () => setSelected( `original` )
  const selectVerified = () => setSelected( `verified` )

  const { styleOverrides } = useAddressStyleStore()
  const { updatePatientAddress } = useUpdateAddress()
  const { ctaStyle, buttonClassName } = styleOverrides

  const handleContinue = () => {
    setSmartyValidationMessage( `` )
    if ( selected === `original` ) setCurrentView( `confirm` )
    else {
      setAddress({
        ...address, // This brings over firstName, lastName
        ...verifiedAddress
      })
      setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api
      if ( hideAddressConfirm ) updatePatientAddress( addressPatientConfirmed, true )

      return setCurrentView( `complete` )
    }
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="flex flex-col items-start gap-4">
        <h1 className="text-lg text-center mt-5 w-full bg-lightGray text-black py-2 rounded-t-md">{`Verify Your Address`}</h1>
        {/* This is if we ever want to display the smarty error/suggestion message <div className="self-center"><Alert text={smartyValidationMessage} /></div> */}
        <div className="flex flex-col gap-3 items-center w-full">
          <p className="mx-auto font-light">{`Use as entered:`}</p>
          <div className="flex flex-row cursor-pointer gap-2 w-fit" onClick={selectOriginal}>
            <RadioInput
              className="cursor-pointer themed-radio"
              value="yes"
              name={`verifyAddress`}
              checked={selected === `original`}
              onChange={selectOriginal}
            />
            <AddressP address={address} className="font-normal text-pretty w-full" />
          </div>
        </div>
        <div className="flex flex-col gap-3 items-center w-full">
          <p className="mx-auto font-light">{`Use suggested:`}</p>
          <div className="flex flex-row cursor-pointer gap-2 w-fit" onClick={selectVerified}>
            <RadioInput
              className="cursor-pointer themed-radio"
              value="yes"
              name={`verifyAddress`}
              checked={selected === `verified`}
              onChange={selectVerified}
            />
            <HighlightP verifiedAddress={verifiedAddress} className="font-semibold text-pretty w-full" />
          </div>
        </div>

        <div className="w-full flex flex-col mt-5 items-center">
          <button className={buttonClassName} onClick={handleContinue}>{`Continue`}</button>
        </div>

        <div className="w-full flex flex-col items-center justify-center">
          <p className={ctaStyle} onClick={() => setCurrentView( `edit` )}>{`Edit Address`}</p>
        </div>
      </div>
    </div>
  )
}