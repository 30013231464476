export default function PageTitle({className}: {className?: string}) {
  return (
    <>
      <div className={`${className ? className : ``} mb-2 lg:mb-6 w-full order-0`}>
        <div className="flex items-center gap-4 justify-center lg:justify-start">
          <svg
            className="h-4 md:h-5"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.99548 1.5C5.0035 1.5 4.156 1.87412 3.42843 2.65366C2.69999 3.43414 2.3379 4.35882 2.3379 5.45455V7.86364H1.54093C1.43606 7.86364 1.35685 7.89594 1.2754 7.9832C1.19272 8.07179 1.15002 8.17348 1.15002 8.31818V14.0455C1.15002 14.1902 1.19272 14.2918 1.2754 14.3804C1.35684 14.4677 1.43606 14.5 1.54093 14.5H10.45C10.5549 14.5 10.6341 14.4677 10.7156 14.3804C10.7982 14.2918 10.8409 14.1902 10.8409 14.0455V8.31818C10.8409 8.17348 10.7982 8.07179 10.7156 7.9832C10.6341 7.89594 10.5549 7.86364 10.45 7.86364H9.65305V5.45455C9.65305 4.35882 9.29097 3.43414 8.56253 2.65366C7.83496 1.87412 6.98746 1.5 5.99548 1.5ZM2.69738 1.97134C3.60314 1.00088 4.71069 0.5 5.99548 0.5C7.28027 0.5 8.38782 1.00088 9.29358 1.97134C10.1985 2.94087 10.6531 4.11088 10.6531 5.45455V6.87801C10.959 6.92219 11.2286 7.06735 11.4466 7.30089C11.7104 7.58351 11.8409 7.93258 11.8409 8.31818V14.0455C11.8409 14.4311 11.7104 14.7801 11.4466 15.0627C11.1816 15.3467 10.8401 15.5 10.45 15.5H1.54093C1.15085 15.5 0.809366 15.3467 0.544345 15.0627C0.280563 14.7801 0.150024 14.4311 0.150024 14.0455V8.31818C0.150024 7.93258 0.280564 7.58351 0.544345 7.30089C0.76231 7.06735 1.032 6.92219 1.3379 6.87801V5.45455C1.3379 4.11088 1.79249 2.94087 2.69738 1.97134ZM3.95022 3.31367C4.50485 2.71942 5.1954 2.40909 5.99548 2.40909C6.79556 2.40909 7.48611 2.71942 8.04074 3.31367C8.59429 3.90676 8.87124 4.6299 8.87124 5.45455V7.86364H3.11972V5.45455C3.11972 4.6299 3.39666 3.90676 3.95022 3.31367ZM5.99548 3.40909C5.48394 3.40909 5.05467 3.59592 4.68127 3.99599C4.30679 4.39722 4.11972 4.87389 4.11972 5.45455V6.86364H7.87124V5.45455C7.87124 4.87389 7.68416 4.39722 7.30969 3.99599C6.93629 3.59592 6.50701 3.40909 5.99548 3.40909Z"
              fill="#222222"
            />
          </svg>
          <h1 className="text-center text-2xl md:text-3xl text-gray-700 font-sourceserif4">
            {`Secure Checkout`}
          </h1>
        </div>
      </div>
    </>
  )
}
