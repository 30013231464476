import { IPaymentMethod } from 'components/payments/types'
import { create } from 'zustand'


type PaymentMethodId = string // This is a unique identifier for a payMethod generated by us
type PaymentBlockId = string | `recurring` | `upfront` // This is the identity of where the payMethods are being used (recurring, upfront, etc), This allows us to select multiple payMethods for different blocks
export type SelectedPayMethod = Record<PaymentBlockId, PaymentMethodId>

/* Usage example
* // Case 1 where we want to track the state of payMethods across the application
* const [payMethods, setPayMethods] = usePayMethodsStore( ( state ) => [state.payMethods, state.setPayMethods] )
* // Case 2 where we want to select a payMethod, based on a blockId (blockId will generally be either recurring or upfront
* const { toggleSelectedPayMethod, getSelectedPayMethod } = usePayMethodsStore()
* const selectedUpfrontPayMethod = getSelectedPayMethod( 'upfront' )
* const selectedRecurringPayMethod = getSelectedPayMethod( 'recurring' )
* const toggleUpfrontPayMethod = () => toggleSelectedPayMethod( 'upfront' )
* */

export type PayMethodsState = {
    payMethods: IPaymentMethod[];
    setPayMethods: ( _payMethods: IPaymentMethod[] ) => void;
    setSelectedPayMethod: ( _payMethod: IPaymentMethod ) => void;
    selectedPayMethod: IPaymentMethod | null;
    getPayMethod: ( _paymentMethodId: PaymentMethodId ) => IPaymentMethod | null;
    setPayMethod: ( _newPayMethod: IPaymentMethod ) => void;
    addPayMethod: ( _newPayMethod: IPaymentMethod ) => void;
    removePayMethod: ( _paymentMethodId: PaymentMethodId ) => void;
    shouldSkipFetch: boolean; // This is used to skip the fetch on mount if the request has already been called by another block
    setShouldSkipFetch: ( _shouldSkipFetch: boolean ) => void;
    selectedPaymentProfiles: SelectedPayMethod;
    setSelectedPaymentProfiles: ( _selectedPaymentProfiles: Record<string, string> ) => void;
    toggleSelectedPayMethod: ( _paymentBlockId : PaymentBlockId, _paymentMethodId: PaymentMethodId ) => void;
    getSelectedPayMethod: ( _paymentBlockId : PaymentBlockId ) => IPaymentMethod | null;
}

export const usePayMethodsStore = create<PayMethodsState>()( ( set, get ) => {
  return {
    payMethods: [], // This state variable tracks all payMethods that are available to the user
    selectedPayMethod: null, // This state variable tracks the payMethod that is selected by the user
    setSelectedPayMethod: ( payMethod: IPaymentMethod ) => {
      return set({
        selectedPayMethod: payMethod
      })
    },
    setPayMethods: ( payMethods: IPaymentMethod[] ) => {
      return set({
        payMethods
      })
    },
    getPayMethod: ( _paymentMethodId: PaymentMethodId ) => {
      return get().payMethods.find( ( payMethod ) => { return payMethod.paymentMethodId === _paymentMethodId }) ?? null
    },
    setPayMethod: ( _newPayMethod: IPaymentMethod ) => {
      get().setPayMethods( get().payMethods.map( ( payMethod ) => {
        if ( payMethod.paymentMethodId === _newPayMethod.paymentMethodId ) return _newPayMethod

        return payMethod
      }) )
    },
    addPayMethod: ( _newPayMethod: IPaymentMethod ) => {
      get().setPayMethods( [
        ...get().payMethods,
        _newPayMethod
      ] )
    },
    removePayMethod: ( _paymentMethodId: PaymentMethodId ) => {
      get().setPayMethods( get().payMethods.filter( ( payMethod ) => {
        return payMethod.paymentMethodId !== _paymentMethodId
      }) )
    },
    shouldSkipFetch: false, // This state variable tracks whether or not we should skip the fetch on mount if the request has already been called by another block
    setShouldSkipFetch: ( shouldSkipFetch: boolean ) => {
      return set({
        shouldSkipFetch
      })
    },
    selectedPaymentProfiles: {}, // This state variable tracks what payMethods the use selects for each block (upfront, recurring, etc)
    setSelectedPaymentProfiles: ( selectedPaymentProfiles: Record<string, string> ) => {
      return set({
        selectedPaymentProfiles
      })
    },
    toggleSelectedPayMethod: ( _paymentBlockId: string, _paymentMethodId: string ) => { // This function selects/deselects a payMethod based on the paymentBlockId
      const _selectedPaymentProfiles = {
        ...get().selectedPaymentProfiles
      }

      const selectedPayMethod = _selectedPaymentProfiles[ _paymentBlockId ] // This is the currently selected payMethod for the given block
      if ( !selectedPayMethod ) _selectedPaymentProfiles[ _paymentBlockId ] = _paymentMethodId // if unselected we need to select the payMethod
      else { // We need to unselect the current payMethod
        delete _selectedPaymentProfiles[ _paymentBlockId ]
        if ( selectedPayMethod !== _paymentMethodId ) _selectedPaymentProfiles[ _paymentBlockId ] = _paymentMethodId // If the payMethod is not the same as the one we are unselecting, we need to select the new payMethod
      }


      return set({
        selectedPaymentProfiles: {
          ..._selectedPaymentProfiles
        }
      })
    },
    getSelectedPayMethod: ( _paymentBlockId: string ) => { // This function returns the payMethod that is selected for a given block
      const paymentMethodId = get().selectedPaymentProfiles[ _paymentBlockId ]

      return get().payMethods.find( ( payMethod : IPaymentMethod ) => { return payMethod.paymentMethodId === paymentMethodId }) ?? null
    }
  }
})

export default usePayMethodsStore