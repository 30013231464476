export default function Footer() {
  return (
    <>
      <div className="p-8 text-center bg-[#F5F5F5] justify-self-end">
        <p className="text-xs pb-4">
          {`Aeroflow Diabetes | 65 Beale Road, Arden, NC 28704`}
        </p>
        <div className="text-xs flex justify-center gap-2">
          <div className="flex items-center">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9167 15.0001C13.7222 15.0001 12.558 14.7338 11.4239 14.2012C10.2894 13.669 9.28481 12.9653 8.41 12.0901C7.53481 11.2153 6.83111 10.2107 6.29889 9.07623C5.7663 7.94216 5.5 6.7779 5.5 5.58346C5.5 5.41679 5.55556 5.2779 5.66667 5.16679C5.77778 5.05568 5.91667 5.00012 6.08333 5.00012H8.33333C8.46296 5.00012 8.5787 5.04179 8.68056 5.12512C8.78241 5.20846 8.84259 5.31494 8.86111 5.44457L9.22222 7.38901C9.24074 7.51864 9.23852 7.6366 9.21556 7.7429C9.19222 7.84957 9.13889 7.94457 9.05556 8.0279L7.70833 9.38901C8.09722 10.0557 8.58574 10.6807 9.17389 11.264C9.76167 11.8473 10.4074 12.352 11.1111 12.7779L12.4167 11.4723C12.5 11.389 12.6089 11.3264 12.7433 11.2846C12.8774 11.2431 13.0093 11.2316 13.1389 11.2501L15.0556 11.639C15.1852 11.6668 15.2917 11.7292 15.375 11.8262C15.4583 11.9236 15.5 12.0372 15.5 12.1668V14.4168C15.5 14.5835 15.4444 14.7223 15.3333 14.8335C15.2222 14.9446 15.0833 15.0001 14.9167 15.0001ZM7.18056 8.33346L8.09722 7.41679L7.86111 6.11123H6.625C6.6713 6.49086 6.73611 6.86586 6.81944 7.23623C6.90278 7.6066 7.02315 7.97234 7.18056 8.33346ZM14.3889 13.8612V12.639L13.0833 12.3751L12.1528 13.3057C12.5139 13.4631 12.882 13.5881 13.2572 13.6807C13.632 13.7733 14.0093 13.8335 14.3889 13.8612Z"
                fill="#222222"
              />
            </svg>
            <a href="tel:18884566755" className="text-indigo">{`1-866-456-6755`}</a>
          </div>
          <div className="flex items-center">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 6.00012H16.5V7.18021L10.5 9.94944L4.5 7.18021V6.00012ZM4.5 8.28158V14.0001H16.5V8.28158L10.7095 10.9541C10.5766 11.0155 10.4234 11.0155 10.2905 10.9541L4.5 8.28158ZM3.5 6.00012C3.5 5.44784 3.94772 5.00012 4.5 5.00012H16.5C17.0523 5.00012 17.5 5.44784 17.5 6.00012V14.0001C17.5 14.5524 17.0523 15.0001 16.5 15.0001H4.5C3.94772 15.0001 3.5 14.5524 3.5 14.0001V6.00012Z"
                fill="#222222"
              />
            </svg>
            <a href="mailto:info@aeroflowdiabetes.com" className="text-indigo">
              {`info@aeroflowdiabetes.com`}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}