import LoadingSpinner from "components/LoadingSpinner"
import { ReactNode } from "react"

export default function DropdownContent({shouldShow, isLoading = false, children} : {shouldShow: boolean, isLoading?: boolean, children : ReactNode}) : JSX.Element {
  if ( !shouldShow ) return <></>

  return (
    <div className="absolute z-10 w-full bg-white -mt-2 rounded-lg shadow-lg max-h-screen overflow-scroll">
      {isLoading ? (
        <div className="max-w-md mx-auto my-10">
          <LoadingSpinner />
        </div>
      ) : (
        children
      )}
    </div>
  )
}