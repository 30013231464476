import { useAddressStore } from "state"
import { Address } from "types/address"
import Highlight from "./Highlight"

export default function HighlightP({ verifiedAddress, className } : { verifiedAddress: Address, className?: string }) : JSX.Element {
  const { address } = useAddressStore()

  // remove street 2 from street 1 for highlight
  const street1 = verifiedAddress.street2 ? address.street.replace( ` ${verifiedAddress.street2}`, `` ) : address.street

  return (
    <p className={className ?? `text-center self-start`}>
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={street1 === verifiedAddress.street}>
        {verifiedAddress.street}
      </Highlight>{`, `}
      {
        verifiedAddress?.street2 && (
          <>
            <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={address.street2 === verifiedAddress.street2}>
              {verifiedAddress.street2}
            </Highlight>
            {`, `}
          </>
        )
      }
      <Highlight highlightClassName="bg-warning font-bold" shouldNotHighlight={address.city === verifiedAddress.city}>{verifiedAddress.city}</Highlight>{`, `}
      <Highlight highlightClassName="bg-warning font-bold" shouldNotHighlight={address.state === verifiedAddress.state}>{verifiedAddress.state}</Highlight>{` `}
      <Highlight highlightClassName="bg-warning font-bold" shouldNotHighlight={address.zipCode === verifiedAddress.zipCode}>{verifiedAddress.zipCode}</Highlight>
    </p>
  )
}