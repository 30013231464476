import { useAddressStore } from 'state'
import { startCase } from 'lodash'
import { CheckboxInput } from 'components/inputs/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAddressStyleStore } from '../style'
import { useUpdateAddress } from '../hooks/useUpdateAddress'

export type ViewAddressProps = {
  addressType?: `shipping` | `billing` | ``;
  hideAddressConfirm: boolean;
  hideName?: boolean;
  hideEdit?: boolean;
}

export default function ViewAddress({ addressType = ``, hideEdit, hideAddressConfirm, hideName } : ViewAddressProps ) : JSX.Element {

  const { address, setCurrentView, addressPatientConfirmed, addressWebConfirmed } = useAddressStore()
  const [ isAddressComplete, setIsAddressComplete ] = useAddressStore( state => [ state.isAddressComplete, state.setIsAddressComplete ] )
  const { styleOverrides } = useAddressStyleStore()
  const { view : styles, ctaStyle } = styleOverrides

  const { updatePatientAddress, isUpdatingPatientAddress } = useUpdateAddress()

  const startEdit = () => {
    setIsAddressComplete( false )
    setCurrentView( `edit` )
  }

  const handleCheckboxChange = () => {
    if ( !isAddressComplete ) updatePatientAddress( addressPatientConfirmed, addressWebConfirmed )
    else setIsAddressComplete( false )
  }

  const address1 = address?.street?.toLowerCase() ?? ``
  const address2 = address?.street2?.length ? `, ${address.street2.toLowerCase()}` : ``

  return (
    <>
      <div className="flex justify-between flex-start mb-2">
        <span className="font-semibold text-base">
          {`Ship to: `}
        </span>
        { !hideEdit &&
          <div>
            <a className={ctaStyle} onClick={startEdit}>{`Edit ${startCase( addressType )}`}</a>
          </div>
        }
      </div>
      { !hideName && <p className={styles?.textStyle}>{`${address?.firstName?.toLowerCase() ?? ``} ${address?.lastName?.toLowerCase() ?? ``}`}</p>}
      <p className={styles?.textStyle}>{`${address1}${address2}`}</p>
      <p className={styles?.textStyle}>{`${address?.city?.toLowerCase() ?? ``}, ${address?.state ?? ``} ${address?.zipCode}`}</p>
      {hideAddressConfirm ? null : (
        <CheckboxInput
          name="current_shipping"
          wrapperClassName={styles?.inputWrapperClassName}
          className={styles?.inputClassName}
          labelClassName={styles?.labelClassName}
          value={isAddressComplete}
          onChange={handleCheckboxChange}
          id="addressInput"
          reference={undefined}
          label={isUpdatingPatientAddress ? `Saving Address...` : `I verify that this is my current shipping address`}
          disabled={isUpdatingPatientAddress}
          hidden={isUpdatingPatientAddress}
        />
      )}

      {isUpdatingPatientAddress && <div className="mx-auto mt-4 -pb-10"><LoadingSpinner width="4rem" height="4rem" /></div>}
    </>
  )
}