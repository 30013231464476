import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePayMethodsStore } from 'state'
import { buildChargeBody } from 'utils/buildChargeBody'
import { splitMoney } from 'utils/money'
import { reportToSentry } from 'utils/reportToSentry'
import { reportWarningToSentry } from 'utils/Sentry/reportToSentry'
import { getNextMonthDate } from 'utils/time'
import { chargePaymentProfileId } from '../api'
import { AobLoader, BackButton, ProcessPaymentButton, RecurringPaymentBlock, UpfrontPaymentBlock } from '../components'
import { SUMMARY_PAGE_PATH } from '../constants'

const Payments = ({ ...pageProps }) => {
  const {
    invoice,
    payLater,
    aobIsCompleted,
    setPaymentCompleted
  } = pageProps

  // ############# S T A T E  H O O K S #############
  const singlePaymentMethod = usePayMethodsStore( state => { return state.getSelectedPayMethod( `upfront` ) })
  const recurringPaymentMethod = usePayMethodsStore( state => { return state.getSelectedPayMethod( `recurring` ) })
  const [ shouldUseSingleForRecurring, setShouldUseSingleForRecurring ] = useState( true )

  const [ splitSinglePayment, setSplitSinglePayment ] = useState( null )

  const [ paymentLoading, setPaymentLoading ] = useState( false )
  const [ paymentError, setPaymentError ] = useState( `` )

  const [ shouldSkipRecurring, setShouldSkipRecurring ] = useState( false )
  const [ disableSubmitPayment, setDisableSubmitPayment ] = useState( false )
  const retries = useRef( 0 )

  const navigate = useNavigate()

  // ############# E F F E C T  H O O K S #############
  useEffect( () => {
    if ( payLater || invoice?.total_patient_owes === 0 ) pageProps.nextPage()
    else if ( !aobIsCompleted ) navigate( SUMMARY_PAGE_PATH.concat( window.location.search ) )
    else if ( invoice !== null && ( !invoice || !Object.keys( invoice ).length ) ) pageProps.nextPage()
  }, [] )

  const scrollToError = () => {
    window.scrollTo({
      top: 9999,
      behavior: `smooth`
    })
  }

  // ############# PAYMENTS #############
  async function submitPayment() {

    if ( singlePaymentMethod === null ) return setPaymentError( `Please select a payment method and try again.` )
    // clear previous errors before attempting a new payment
    setPaymentError( false )
    setPaymentLoading( true )

    const recurringAuthnetResponse = await submitRecurringRentalPayment()

    if ( recurringAuthnetResponse !== `Successful.` ) {
      setPaymentLoading( false )
      scrollToError()
      if ( recurringAuthnetResponse.includes( `Credit Card expires before the start of the subscription` ) ) return setPaymentError( `Looks like the card you have selected for your subscription will expire by the time the subscription is started. Please select/add another card or give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
      if ( recurringAuthnetResponse.includes( `duplicate subscription` ) ) return setPaymentError( `Looks like you've already made a payment. Please give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
      if ( recurringAuthnetResponse.includes( `record cannot be found` ) ) {
        // This is an authent error code for "record not found" in authnet. This is a rare case where authnet has not yet received the payment profile shortly after it was just saved since they implement a load balancer
        reportWarningToSentry( new Error( `Authnet Race Condition Met for Recurring Payment` ) ) // Report to sentry so we can keep track of how often this happens
        if ( retries.current < 3 ) { // If we have not yet retried 3 times, try again
          retries.current += 1

          return await submitPayment() // Who would have thought I would be using recursion in a react app!
        } else return setPaymentError( `We encountered an issue with processing your payment method. This could be an issue related on our end syncing your payment method. Please try again in a few minutes or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )
      }
      setPaymentError( `We encountered an issue when attempting to pay your rental payment with the selected card. Please select/add another card or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )


      return reportToSentry( new Error( `AOB: Failed to make RECURRING payment ${recurringAuthnetResponse ?? ``}` ), {
        recurringAuthnetResponse,
        invoiceDetails: JSON.stringify( invoice ),
        paymentDetails: JSON.stringify( recurringPaymentMethod )
      })
    } else setShouldSkipRecurring( true ) // If a user has completed a recurring charge, but needs to retry the upfront charge we need to know when to skip

    /* Begin Upfront Payment */

    const upfrontAuthnetResponse = await submitUpfrontPayment()

    setPaymentLoading( false )

    if ( upfrontAuthnetResponse === `Successful.` && recurringAuthnetResponse === `Successful.` ) {
      setPaymentCompleted( true )

      return pageProps.nextPage()
    }

    scrollToError()
    if ( upfrontAuthnetResponse.includes( `duplicate subscription` ) ) return setPaymentError( `Looks like you've already made a payment. Please give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
    if ( upfrontAuthnetResponse.includes( `Credit Card expires before the start of the subscription` ) ) return setPaymentError( `Looks like the card you have selected for your subscription will expire by the time the subscription is started. Please select/add another card or give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
    if ( upfrontAuthnetResponse.includes( `record cannot be found` ) ) {
      reportWarningToSentry( new Error( `Authnet Race Condition Met for Upfront Payment` ) )
      if ( retries.current < 3 ) {
        retries.current += 1

        return await submitPayment()
      } else return setPaymentError( `We encountered an issue with processing your payment method. This could be an issue related on our end syncing your payment method. Please try again in a few minutes or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )
    }
    setPaymentError( `We encountered an issue when attempting to pay your upfront payment with the selected card. Please select/add another card or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )

    reportToSentry( new Error( `AOB: Failed to make UPFRONT payment ${upfrontAuthnetResponse ?? ``}` ), {
      upfrontAuthnetResponse,
      recurringAuthnetResponse,
      invoiceDetails: JSON.stringify( invoice ),
      paymentDetails: JSON.stringify( singlePaymentMethod )
    })
  }

  async function submitRecurringRentalPayment() {
    if ( !invoice.est_monthly_due || shouldSkipRecurring ) return `Successful.`
    const recurringChargeDetails = {
      startDate: getNextMonthDate().toISOString()
        .slice( 0, 10 ),
      intervalLength: 1,
      totalOccurrences: invoice.number_of_months,
      name: `CGM Supplies Monthly Rental Payment`
    }
    const paymentMethod = shouldUseSingleForRecurring ? singlePaymentMethod : recurringPaymentMethod
    const {paymentProfileId} = paymentMethod
    const rentalChargeBody = buildChargeBody( invoice.est_monthly_due, {
      ...recurringChargeDetails,
      initialPayment: false,
      ...( invoice?.invoice_number && {
        invoiceNumber: invoice.invoice_number
      })
    })

    return await chargePaymentProfileId( paymentProfileId, rentalChargeBody )
  }

  async function submitUpfrontPayment() {
    const amount = splitSinglePayment ? splitMoney( invoice.total_patient_owes, 3 ) : invoice.total_patient_owes
    const chargeDetails = {
      ...( splitSinglePayment && { // If it's a split payment we need to add recurring details
        startDate: new Date().toISOString()
          .slice( 0, 10 ),
        intervalLength: 1,
        totalOccurrences: 3,
        initialPayment : true,
        name: `Diabetes CGM Equipment Payment`
      }),
      ...( invoice?.invoice_number && {
        invoiceNumber: invoice.invoice_number
      })
    }
    const upfrontChargeBody = buildChargeBody( amount, chargeDetails )

    return await chargePaymentProfileId( singlePaymentMethod.paymentProfileId, upfrontChargeBody )
  }


  const isSinglePaymentMethodComplete = singlePaymentMethod?.cardNumber || singlePaymentMethod?.accountNumber
  const isRecurringPaymentMethodComplete = isSinglePaymentMethodComplete && ( ( recurringPaymentMethod?.cardNumber || recurringPaymentMethod?.accountNumber ) || shouldUseSingleForRecurring )

  if ( !invoice ) return <AobLoader message={`Please hold tight we are looking up your payment information. This may take a few seconds. Thanks for your patience!`} />

  if ( paymentLoading ) return <AobLoader withLogo={false} message={`Please hold tight while we process your payment...`} />

  return (
    <div className="max-w-full mx-auto w-full md:w-10/12 lg:w-1/2 flex flex-col gap-10 md:gap-16 bg-gray3">
      <div className="bg-white flex flex-col gap-7 px-4 md:px-10 items-center">
        <div className="relative">
          <p className="text-2xl md:text-3xl font-bold text-center mt-28">{`Check Out`}</p>
          {
            !paymentLoading && !paymentError &&
            <BackButton handleClick={pageProps.prevPage} tailwindPositioning="bottom-1 -left-14" />
          }
        </div>
        <p className="md:text-lg text-center font-light max-w-2xl">{`Your payment amounts displayed below are the estimated costs for your order based on how your plan usually covers supplies.`}</p>
        <p className="md:text-lg text-center font-light max-w-2xl">{`Your item(s) will ship upon payment below and receipt of your prescription from your doctor as required by your health insurance provider.`}</p>
        <div className="py-2 text-center w-full md:w-11/12">
          <UpfrontPaymentBlock
            splitSinglePayment={splitSinglePayment}
            setSplitSinglePayment={setSplitSinglePayment}
            invoice={invoice}
            setDisableSubmitPayment={setDisableSubmitPayment}
          />
          <RecurringPaymentBlock
            invoice={invoice}
            useSamePaymentCheck={shouldUseSingleForRecurring}
            setUseSamePaymentCheck={setShouldUseSingleForRecurring}
          />
          <ProcessPaymentButton
            onClick={submitPayment}
            disabled={paymentLoading || splitSinglePayment === null || !isSinglePaymentMethodComplete || !isRecurringPaymentMethodComplete || disableSubmitPayment}
            paymentError={paymentError}
          />
        </div>
      </div>
    </div>
  )
}

Payments.propTypes = {
  invoice: PropTypes.shape({
    total_patient_owes: PropTypes.number,
    invoice_number: PropTypes.string,
    recurring_payment: PropTypes.number,
    single_payment: PropTypes.number,
    number_of_payments: PropTypes.number
  }),
  allowPayment: PropTypes.bool,
  setPaymentCompleted: PropTypes.func,
  payLater: PropTypes.bool
}

export default Payments
