import { useDoctorReferStore } from "modules/doctorRefer/DoctorReferStore"
import PropTypes from 'prop-types'
import { formatMoney } from "utils/money"

export default function CostBreakdown({invoice}) {
  const [ hasReader, readerCost, sensorCost ] = useDoctorReferStore( state => [ state.refer.hasReader, state.refer.readerCost, state.refer.sensorCost ] )

  const upFrontCost = hasReader ? sensorCost : sensorCost + readerCost

  return (
    <div className="flex flex-col">
      <details>
        <summary className="text-lg md:text-xl font-sourceserif4 cursor-pointer">
          {`Cost Breakdown`}
        </summary>
        <div className="my-4">
          <p className="text-xs font-semibold text-indigo md:text-base">
            {`After insurance, you pay `}
            <span className="font-light text-[16px] mx-[2px]">{formatMoney( upFrontCost )}</span>
            {` upfront.`}
          </p>
          <div className="mt-4">
            {
              invoice?.details.filter( ( detail ) => ( hasReader === null || hasReader === false ) || ( hasReader === true && detail.is_sensor === true ) ).map( ( detail, index ) => (
                <div key={index} className="flex justify-between mb-2">
                  <p className="text-xs capitalize">{detail.item_description.toLowerCase()}</p>
                  <div className="flex flex-col items-end">
                    {
                      !detail.is_sensor ? (
                        <>
                          <p className="text-xs">{formatMoney( readerCost )}</p>
                          <p className="text-xs">{`(one-time)`}</p>
                        </>
                      ) :
                        (
                          <>
                            <p className="text-xs">{formatMoney( sensorCost )}</p>
                            <p className="text-xs">{`(for 1st month)`}</p>
                          </>
                        )
                    }
                  </div>
                </div>
              ) )
            }
          </div>
        </div>
        <div>
          <p className="text-xs font-semibold text-indigo md:text-base">
            {`After insurance, you will pay `}
            <span className="font-light text-[16px] mx-[2px]">{formatMoney( sensorCost )}</span>
            {` every month.`}
          </p>
          <div className="mt-4">
            {
              invoice?.details.filter( detail => detail.is_sensor === true ).map( ( detail, index ) => (
                <div key={index} className="flex justify-between mb-2">
                  <p className="text-xs capitalize">{detail.item_description.toLowerCase()}</p>
                  <div className="flex flex-col items-end">
                    <p className="text-xs">{formatMoney( sensorCost )}</p>
                    <p className="text-xs">{`(each month)`}</p>
                  </div>
                </div>
              ) )
            }
          </div>
        </div>
      </details>
    </div>
  )
}

CostBreakdown.propTypes = {
  invoice: PropTypes.object.isRequired
}