import * as React from "react"
import { TextInput } from "components/inputs/components"
import { TextInputProps } from "components/inputs/types"
import { useAddressStore } from "state"
import { SmartyAddressAutocomplete } from "types/address"
import { DropdownContainer, DropdownContent } from "components/dropdown"
import { forwardRef } from "react"
import { debounce } from "lodash"
import { buildAddressString, fetchAutoCompleteAddresses } from "./utils"
import { reportToSentry } from "utils/reportToSentry"

const TypeAheadTextInput = forwardRef( ( textInputProps : Partial<TextInputProps>, ref ) => {

  const { onChange, onBlur, className } = textInputProps
  const { address, setAddress, setAddressPatientConfirmed } = useAddressStore()
  const [ showPredictions, setShowPredictions ] = React.useState( false )
  const [ loading, setLoading ] = React.useState( false )
  const [ suggestions, setSuggestions ] = React.useState<SmartyAddressAutocomplete[]>( [] )

  const handleGetSuggestions = async ( search: string ) => {
    setLoading( true )
    const autocompleteResponse = await fetchAutoCompleteAddresses( search ).catch( error => {
      reportToSentry( new Error( `Smarty Autocomplete Error`, {
        cause: error
      }), {
        search
      })
    })
    setLoading( false )
    if ( autocompleteResponse?.suggestions?.length ) {
      setSuggestions( autocompleteResponse.suggestions )
      setShowPredictions( true )
    } else setShowPredictions( false )
  }

  const debouncedSuggestionFunction = React.useCallback( debounce( handleGetSuggestions, 500 ), [] )

  const handlePlaceClick = ( place : SmartyAddressAutocomplete ) => {
    const { firstName, lastName } = address
    setAddress({
      firstName,
      lastName,
      street: place.street_line,
      street2: place.secondary,
      city: place.city,
      state: place.state,
      zipCode: place.zipcode
    })
    setShowPredictions( false )
    setAddressPatientConfirmed( true ) // Means the autocomplete api was used to verify the address
  }

  const handleChange = ( e : React.ChangeEvent<HTMLInputElement> ) => {
    setAddressPatientConfirmed( false ) // reset the patient confirmed flag (This is used to track if the address is verified by the autocomplete api)
    onChange && onChange( e )
    if ( e.currentTarget.value.length > 3 ) debouncedSuggestionFunction( e.currentTarget.value )
    else {
      setShowPredictions( false )
      setSuggestions( [] )
    }
  }

  const handleBlur = ( e : React.FocusEvent<HTMLDivElement> ) => {
    // hide predictions if the user clicks outside of the Dropdown container
    if ( e.relatedTarget === null || !e.currentTarget?.contains( e.relatedTarget as Node ) ) {
      setShowPredictions( false )
    }
  }

  return (
    <DropdownContainer onBlur={handleBlur} tabIndex={-1}>
      <TextInput
        {...textInputProps}
        value={textInputProps.value}
        onChange={handleChange}
        className={`input ${className}`}
        onBlur={onBlur}
        reference={ref as React.LegacyRef<HTMLInputElement> | undefined}
      />
      <DropdownContent shouldShow={showPredictions} isLoading={loading}>
        <ul>
          {suggestions.map( ( prediction: SmartyAddressAutocomplete, index ) => (
            <li
              id="place-prediction"
              tabIndex={0}
              key={prediction.street_line}
              className="cursor-pointer p-4 hover:bg-aqua2"
              onMouseDown={( e ) => e.preventDefault()}
              onKeyDown={( e ) => { if ( e.code === `Enter` ) { handlePlaceClick( suggestions[index] ) } }}
              onClick={() => handlePlaceClick( suggestions[index] )}
            >{buildAddressString( prediction )}</li>
          ) )}
        </ul>
      </DropdownContent>
    </DropdownContainer>
  )
})

export default TypeAheadTextInput