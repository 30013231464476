import useValidate from "hooks/useValidate"
import { useRef, useState } from "react"
import postDemographics from "utils/demographics"
import getHash from "utils/getHash"

export default function EmailCollect() {
  const [ loading, setLoading ] = useState( false )
  const [ saveEmailSuccess, setSaveEmailSuccess ] = useState( false )

  const hash = getHash()

  const refs = {
    email: useRef<HTMLInputElement>( null )
  }
  const { evaluateErrorsOnSubmit, evaluateErrorsOnBlur, handleTouch, formErrors, errorsPresent } = useValidate( refs )

  const handleOnFocus = ( e: React.FocusEvent<HTMLInputElement> ) => {
    handleTouch( e.target.name )
  }

  const handleOnBlur = ( e: React.FocusEvent<HTMLInputElement> ) => {
    handleTouch( e.target.name )
    evaluateErrorsOnBlur()
  }

  const handleSaveEmailAddress = async ( e: React.FormEvent<HTMLFormElement> ) => {
    e.preventDefault()
    setLoading( true )
    const hasAnError = evaluateErrorsOnSubmit()
    if ( hasAnError ) {
      setLoading( false )

      return
    }

    const formData = new FormData( e.target as HTMLFormElement )
    const email = formData.get( `email` ) as string | null

    const saveEmailResponse = await postDemographics( `PatientHash ${hash}`, {
      emailAddress: email ?? undefined
    })

    if ( saveEmailResponse ) {
      setSaveEmailSuccess( true )
    }

    setLoading( false )
  }

  if ( loading ) {
    return (
      <div className="py-8 px-4 bg-skyBlue">
        <p className="mb-2 text-base font-light font-sourceserif4">{`Saving email address...`}</p>
      </div>
    )
  }

  if ( saveEmailSuccess ) {
    return (
      <div className="py-8 px-4 bg-skyBlue text-center">
        <h1 className="mb-2 text-base font-sourceserif4">{`Thank you!`}</h1>
        <p className="mb-2 text-xs">{`Look out for order tracking and reminder emails from Aeroflow Diabetes.`}</p>
      </div>
    )
  }

  return (
    <div className="pt-5 pb-6 px-4 lg:py-6 lg:px-8 bg-skyBlue">
      <p className="mb-2 text-base font-light font-sourceserif4 lg:text-xl lg:leading-tight">{`Provide your email address to receive order tracking and reminders.`}</p>
      <form className="flex flex-col items-start" onSubmit={handleSaveEmailAddress}>
        <div className="mb-2">
          <label className="text-xs font-light" htmlFor="email">{`Email Address`}</label>
          <span className="text-red-500 text-sm">{` *`}</span>
        </div>
        <input
          type="email"
          name="email"
          ref={refs.email}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          required
          className="w-full py-1 px-4 text-sm md:text-base border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:border-transparent mb-4"
        />
        {
          errorsPresent && formErrors.email && (
            <p className="text-xs text-error mb-2">{formErrors.email}</p>
          )}
        <button
          type="submit"
          disabled={loading || errorsPresent}
          className="bg-[#83c6d6] inline-block rounded py-2 px-4 text-md disabled:bg-[#e0e0e0] disabled:cursor-not-allowed disabled:text-gray-800 text-base md:text-base font-normal"
        >
          {`Submit`}
        </button>
      </form>
    </div>
  )
}
