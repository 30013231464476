// This Component will be use in Doctor referal project

import { useState, useRef, RefObject, useMemo } from 'react'
import { Formatters, getExpiryYearList, SelectInput, TextInput } from 'components/inputs/components'
import { getValidationHelper } from '../validation'
import { usaStates, months } from 'components/inputs/constants'
import { EditCardMethodProps } from '../props'
import LoadingSpinner from 'components/LoadingSpinner'
import '../style.css'
import { usePayMethodsStore } from 'state'

export default function EditCardMethod({payMethod, setPayMethod, onSave, onCancel, displayLoading, displayError, newPayment = true } : EditCardMethodProps ) {

  const [ currentFocus, setCurrentFocus ] = useState( `` )
  const [ errors, setErrors ] = useState<Record<string, string>>({
    cardholderFirstName: ``,
    cardholderLastName: ``,
    cardNumber: ``,
    expMonth: ``,
    expYear: ``,
    address: ``,
    city: ``,
    state: ``,
    zip: ``,
    ccv: ``
  })

  const inputRefs = {
    cardholderFirstName: useRef<HTMLInputElement>( null ),
    cardholderLastName: useRef<HTMLInputElement>( null ),
    cardNumber: useRef<HTMLInputElement>( null ),
    expMonth: useRef<HTMLSelectElement>( null ),
    expYear: useRef<HTMLSelectElement>( null ),
    address: useRef<HTMLInputElement>( null ),
    city: useRef<HTMLInputElement>( null ),
    state: useRef<HTMLSelectElement>( null ),
    zip: useRef<HTMLInputElement>( null ),
    ccv: useRef<HTMLInputElement>( null )
  } as Record<string, RefObject<HTMLInputElement | HTMLSelectElement>>

  const { payMethods } = usePayMethodsStore()

  const evaluateErrors = () => {
    return Boolean( Object.keys( inputRefs ).find( ( fieldName ) => {
      if ( !newPayment && ( fieldName === `cardNumber` || fieldName === `ccv` ) ) return false
      const hasError = errors[fieldName] !== ``
      const isIncomplete = !inputRefs[fieldName]?.current?.value

      return hasError || isIncomplete
    }) )
  }

  const shouldSubmitBeDisabled = useMemo( evaluateErrors, [ errors ] )

  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    const { name, value } = event.currentTarget
    // Pass up data
    setPayMethod({
      ...payMethod,
      [name]: value
    })
    // Check for errors
    const { errorMessage, validator } = getValidationHelper( name )
    const _errors = {
      ...errors
    }
    // If valid set error to empty string
    _errors[ name ] = validator( value ) ? `` : errorMessage
    setErrors( _errors )
  }

  function handleBlur() {
    setCurrentFocus( `` )
  }

  function handleFocus( event : React.ChangeEvent<HTMLInputElement> ) {
    setCurrentFocus( event.target.name )
  }


  const inputHandlers = {
    onChange: handleInputChange,
    onBlur: handleBlur,
    onFocus: handleFocus
  }

  return (
    <div className="payment-method-form">
      <fieldset>
        <legend>{`Credit/Debit Card Info`}</legend>
        <div className="inputs-container">
          <TextInput
            id={`cardholderFirstName`}
            name="cardholderFirstName"
            label="Cardholder's First Name"
            value={payMethod.cardholderFirstName}
            errorMessage={currentFocus !== `cardholderFirstName` ? errors.cardholderFirstName : ``}
            required
            reference={inputRefs.cardholderFirstName as RefObject<HTMLInputElement>}
            {...inputHandlers}
          />

          <TextInput
            id={`cardholderLastName`}
            name="cardholderLastName"
            label="Cardholder's Last Name"
            value={payMethod.cardholderLastName}
            errorMessage={currentFocus !== `cardholderLastName` ? errors.cardholderLastName : ``}
            reference={inputRefs.cardholderLastName as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
          />
        </div>
        {
          newPayment && (
            <div className="inputs-container">
              <TextInput
                id="cardNumber"
                name="cardNumber"
                label="Card Number"
                type="tel"
                value={payMethod.cardNumber ?? ``}
                formatter={{
                  function: Formatters.creditCard
                }}
                errorMessage={currentFocus !== `cardNumber` ? errors.cardNumber : ``}
                reference={inputRefs.cardNumber as RefObject<HTMLInputElement>}
                required
                {...inputHandlers}
              />
            </div>
          )
        }
        <div className="inputs-container">
          <SelectInput
            id="expMonth"
            name="expMonth"
            label="Exp. Month"
            value={payMethod.expMonth}
            errorMessage={currentFocus !== `expMonth` ? errors.expMonth : ``}
            reference={inputRefs.expMonth as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
          >
            <option value="" hidden>{`Exp. Month`}</option>
            {months.map( month => {
              return <option key={month.value} value={month.value}>{month.text}</option>
            })}
          </SelectInput>

          <SelectInput
            id="expYear"
            name="expYear"
            label="Exp. Year"
            value={payMethod.expYear}
            errorMessage={currentFocus !== `expYear` ? errors.expYear : ``}
            reference={inputRefs.expYear as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
          >
            <option value="" hidden>{`Exp. Year`}</option>
            {getExpiryYearList().map( expiry => {
              return <option key={expiry.YY} value={expiry.YY}>{expiry.YYYY}</option>
            })}
          </SelectInput>
          {
            newPayment && (
              <TextInput
                id="ccv"
                name="ccv"
                label="CCV"
                type="tel"
                value={payMethod.ccv}
                errorMessage={currentFocus !== `ccv` ? errors.ccv : ``}
                reference={inputRefs.ccv as RefObject<HTMLInputElement>}
                required
                {...inputHandlers}
              />
            )
          }
        </div>
      </fieldset>
      <fieldset>
        <legend>{`Billing Adress`}</legend>
        <div className="inputs-container">
          <TextInput
            id="address"
            name="address"
            label="Address"
            value={payMethod.address}
            errorMessage={currentFocus !== `address` ? errors.address : ``}
            reference={inputRefs.address as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
          />
        </div>
        <div className="inputs-container">
          <TextInput
            id="city"
            name="city"
            label="City"
            value={payMethod.city}
            errorMessage={currentFocus !== `city` ? errors.city : ``}
            reference={inputRefs.city as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
          />
          <SelectInput
            id="state"
            name="state"
            label="State"
            value={payMethod.state}
            errorMessage={currentFocus !== `state` ? errors.state : ``}
            formatter={{
              function: Formatters.state
            }}
            reference={inputRefs.state as RefObject<HTMLSelectElement>}
            {...inputHandlers}
            required
          >
            <option value="" hidden>{`State`}</option>
            {usaStates.map( state => {
              return <option key={state.value} value={state.value}>{state.value}</option>
            })}
          </SelectInput>
          <TextInput
            id="zip"
            name="zip"
            label="Zip"
            type="tel"
            value={payMethod.zip}
            formatter={{
              function: Formatters.zipCode
            }}
            reference={inputRefs.zip as RefObject<HTMLInputElement>}
            required
            errorMessage={currentFocus !== `zip` ? errors.zip : ``}
            {...inputHandlers}
          />
        </div>
      </fieldset>

      { displayLoading && <div className="my-2"><LoadingSpinner /></div> }

      { displayError && <p className="my-2 text-error max-w-lg">{displayError}</p> }

      <div className="actions-container">
        <button
          className="action button save"
          disabled={shouldSubmitBeDisabled || displayLoading}
          onClick={onSave}
        >
          {`Save`}
        </button>
        {
          payMethods.length >= 1 && (
            <button
              className="action button cancel"
              disabled={displayLoading}
              onClick={onCancel}
            >
              {`Cancel`}
            </button>
          )
        }
      </div>
    </div>
  )
}