import { RadioCheckboxInputCustom } from 'components/inputs/components'
import { NavButton } from 'components/navButton'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import {
  chargePaymentProfileId,
  ChargeProfileIdBody
} from 'modules/aobPayments/api'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import { useRef, useState } from 'react'
import usePayMethodsStore from 'state/payMethods'
import { buildChargeBody } from 'utils/buildChargeBody'
import {
  reportToSentry,
  reportWarningToSentry
} from 'utils/Sentry/reportToSentry'
import { getAuth } from '../../modules/aobPayments/fetchPromises'

interface AcceptTOCProps {
  nextPage: () => void
  // eslint-disable-next-line
  setIsLoading: (isLoading: boolean) => void
  invoice: any
}

export default function AcceptTOC({ nextPage, setIsLoading, invoice }: AcceptTOCProps ) {
  const [ setOrderConfirmation ] = useDoctorReferStore( ( state ) => [ state.setOrderConfirmation ] )
  const [ totalDueToday ] = useDoctorReferStore( ( state ) => [ state.refer.totalDueToday ] )
  const [ paymentError, setPaymentError ] = useState<boolean|string>( `` )
  const [ , setPaymentLoading ] = useState( false )
  const singlePaymentMethod = usePayMethodsStore( state => state.selectedPayMethod )
  const retries = useRef( 0 )


  const [ hasAcceptedTerms, setHasAcceptedTerms ] = useDoctorReferStore(
    state => [ state.refer.hasAcceptedTerms, state.setHasAcceptedTerms ]
  )

  const scrollToError = () => {
    window.scrollTo({
      top: 9999,
      behavior: `smooth`
    })
  }

  async function submitUpfrontPayment() {
    const chargeDetails = {
      ...{
        initialPayment: true,
        name: `Diabetes CGM Equipment Payment`
      },
      ...( invoice?.invoice_number && {
        invoiceNumber: invoice.invoice_number
      })
    }
    const upfrontChargeBody = buildChargeBody(
      totalDueToday.toString(),
      chargeDetails
    ) as ChargeProfileIdBody

    if ( singlePaymentMethod?.paymentProfileId )
      return await chargePaymentProfileId(
        singlePaymentMethod?.paymentProfileId,
        upfrontChargeBody
      )

    return `No payment method selected.`
  }

  // ############# Submit Payment ############
  async function submitPayment(): Promise<string | void> {
    if ( singlePaymentMethod === null && totalDueToday > 0 )
      return setPaymentError(
        `Please select a payment method and try again.`
      )
    // clear previous errors before attempting a new payment
    setPaymentLoading( true )

    /* Begin Upfront Payment */
    const upfrontAuthnetResponse = await submitUpfrontPayment()
    setPaymentLoading( false )

    if ( upfrontAuthnetResponse === `Successful.` ) {
      return `Successful.`
    }

    scrollToError()
    if ( upfrontAuthnetResponse.includes( `duplicate subscription` ) )
      return setPaymentError(
        `Looks like you've already made a payment. Please give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.`
      )
    if (
      upfrontAuthnetResponse.includes(
        `Credit Card expires before the start of the subscription`
      )
    )
      return setPaymentError(
        `Looks like the card you have selected for your subscription will expire by the time the subscription is started. Please select/add another card or give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.`
      )
    if ( upfrontAuthnetResponse.includes( `record cannot be found` ) ) {
      reportWarningToSentry(
        new Error( `Authnet Race Condition Met for Upfront Payment` )
      )
      if ( retries.current < 3 ) {
        retries.current += 1

        return await submitPayment()
      } else
        return setPaymentError(
          `We encountered an issue with processing your payment method. This could be an issue related on our end syncing your payment method. Please try again in a few minutes or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.`
        )
    }

    reportToSentry(
      new Error(
        `AOB: Failed to make UPFRONT payment ${
          upfrontAuthnetResponse ?? ``
        }`
      ),
      {
        upfrontAuthnetResponse,
        invoiceDetails: JSON.stringify( invoice ),
        paymentDetails: JSON.stringify( singlePaymentMethod )
      }
    )
  }
  // ############# END OF Submit Payment ############

  // ############# Submit Order ############
  const submitOrder = async () => {
    setIsLoading( true )
    const { patientHash } = JSON.parse(
      localStorage.getItem( `invoice-control` ) || `{}`
    )
    const url = `${process.env.REACT_APP_AOB_URL}/invoice/${patientHash}/done`

    try {
      const response = await fetch( url, {
        method: `POST`,
        headers: {
          'Content-Type': `application/json`,
          Authorization: getAuth()
        }
      })

      const jsonResult = await response.json()

      if ( jsonResult.meta.status === `OK` ) {
        setOrderConfirmation( true )
        nextPage()
      }
    } catch ( error: any ) {
      console.error( error.message )
    }
  }
  // ############# END OF Submit Order ############

  const handleSubmitOrder = async ( e: React.FormEvent<HTMLFormElement> ) => {
    e.preventDefault()
    if ( !hasAcceptedTerms ) {
      setPaymentError( `Please accept the terms and conditions to proceed.` )
      scrollToError()

      return
    }

    if ( totalDueToday > 0 ) {
      const paymentResponse = await submitPayment()

      if ( paymentResponse === `Successful.` )
        return await submitOrder()
    }
    reportToSentry(
      new Error(
        `Error submitting payment in handlesubmitorder function`
      ),
      {
        invoiceDetails: JSON.stringify( invoice ),
        paymentDetails: JSON.stringify( singlePaymentMethod )
      }
    )

    return await submitOrder()
  }


  return (
    <form onSubmit={handleSubmitOrder} className="m-8 mt-0 lg:m-0">
      <div className="grid grid-cols-[1.25rem_1fr] gap-4 mb-4">
        <RadioCheckboxInputCustom
          type="checkbox"
          name="acceptTerms"
          checked={hasAcceptedTerms}
          id="acceptTerms"
          onChange={() => {
            setHasAcceptedTerms( !hasAcceptedTerms )
          }}
        />

        <label className="text-xs flex gap-2" htmlFor="acceptTerms">
          <span className="text-canyon font-normal">{`* `}</span>
          <div>
            {`By placing this order, I authorize Aeroflow Healthcare to contact me by email, phone, or SMS. Aeroflow will not share or distribute this information. I also agree to `}
            <a
              className="underline"
              href="https://aeroflowdiabetes.com/terms-and-conditions"
            >{`Aeroflow Terms and Conditions.`}</a>
          </div>
        </label>
      </div>
      <NavButton
        options={
          {
            buttonText: `Place Order`,
            className: `bg-indigo rounded text-white py-2 px-4 w-full text-md disabled:bg-[#e0e0e0] disabled:cursor-not-allowed disabled:text-gray-800`
          }
        }
      />
      {paymentError && (
        <p className="text-red-700 text-sm my-2">{paymentError}</p>
      )}
    </form>
  )
}
