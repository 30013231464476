export default function PatientInformation({invoice}: any ) {

  return (
    <div className="m-8 text-center lg:pr-8 lg:mx-0 lg:text-left">
      <p className="text-2xl md:text-3xl font-sourceserif4 mb-6">
        <span className="capitalize">
          {`${invoice?.first_name.toLowerCase()},`}
        </span>
        <br />
        {`your continuous glucose monitoring prescription is ready!`}
      </p>
      <p className="text-xs md:text-base pb-1 lg:inline">
        {`Your doctor has partnered with Aeroflow Diabetes to provide you with your CGM through insurance.`}
      </p>
      <a className="text-sm lg:text-base underline underline-offset-4 lg:inline px-2" href="#faq">
        {`Learn more`}
      </a>
    </div>
  )
}