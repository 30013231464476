import { useState, useEffect } from 'react'
import { fetchProductPricing } from "../fetchPromises"

export default function useFetchProductPricingData() {
  const [ productPricingData, setProductPricingData ] = useState( null )
  const [ error, setError ] = useState( null )
  const [ loading, setLoading ] = useState( true )

  useEffect( () => {
    const abortController = new AbortController()
    const {signal} = abortController

    fetchProductPricing( signal )
      .then( data => {
        setProductPricingData( data )
        setLoading( false )
      })
      .catch( error => {
        setError( error.message )
        setLoading( false )
      })

    return () => abortController.abort()
  }, [] )

  return {
    productPricingData,
    loading,
    error
  }
}